<template>
  <tr>
    <td>
      <input
        type="text"
        autocomplete="on"
        autofocus
        ref="originals"
        class="input"
        :class="{'is-danger': getErrors('originals').length > 0}"
        name="originals"
        v-model.number="copy.originals"
        :disabled="isDisabled"
        :placeholder="$t('copies.originals')"
        max="1000"
      />
      <p
        v-for="error in getErrors('originals')"
        :key="error.errorPath + error.keyword"
        class="help is-danger">
        {{ error.message }}
      </p>
    </td>
    <td>
      <input
        type="text"
        autocomplete="on"
        class="input"
        :class="{'is-danger': getErrors('quantity').length > 0}"
        name="quantity"
        v-model.number="copy.quantity"
        :placeholder="$t('copies.quantity')"
        max="10000"
      />
      <p
        v-for="error in getErrors('quantity')"
        :key="error.errorPath + error.keyword"
        class="help is-danger">
        {{ error.message }}
      </p>
    </td>
    <td>
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input
                type="number"
                autocomplete="on"
                class="input"
                :class="{'is-danger': getErrors('width').length > 0}"
                name="width"
                v-model.number="copy.width"
                :disabled="product.fixed_width > 0"
                :placeholder="$t('copies.width')"
              />
              <p
                v-for="error in getErrors('width')"
                :key="error.errorPath + error.keyword"
                class="help is-danger">
                {{ error.message }}
              </p>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input
                type="number"
                autocomplete="on"
                class="input"
                name="height"
                :class="{'is-danger': getErrors('height').length > 0}"
                v-model.number="copy.height"
                :disabled="product.fixed_height > 0"
                :placeholder="$t('copies.height')"
              />
              <p
                v-for="error in getErrors('height')"
                :key="error.errorPath + error.keyword"
                class="help is-danger">
                {{ error.message }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td v-if="product.sides === 2">
      <div class="field is-horizontal product-sides">
        <div class="field-body">
          <div class="control">
            <label class="button" :class="{ 'bg-cyan': copy.abaa === 2 }">
              <input
                type="radio"
                name="sides"
                value="2"
                v-model.number="copy.abaa"
              />
              A/A
            </label>
          </div>

          <div class="control">
            <label class="button" :class="{ 'bg-cyan': copy.abaa === 1 }">
              <input
                type="radio"
                name="sides"
                value="1"
                v-model.number="copy.abaa"
              />
              A/B
            </label>
          </div>
        </div>
      </div>
    </td>
    <td>
      <textarea
        class="input"
        v-model="copy.remark"
        @keydown.tab.prevent="addRow"
        :placeholder="$tc('copies.remark')"
      ></textarea>
    </td>
    <td>
      <slot />
    </td>
  </tr>
</template>
<script>
export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    disableOriginalsWithId: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    copy: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    formatErrors() {
      return this.errors.map(error => {
        const errorPath = error.dataPath.substr(error.dataPath.indexOf('.') + 1);
        const errorMessage = this.$te(`validation.copies.${errorPath}.${error.keyword}`)
          ? this.$t(`validation.copies.${errorPath}.${error.keyword}`)
          : error.message;
        return {
          errorPath,
          keyword: error.keyword,
          message: errorMessage,
        }
      })
    },
    isDisabled() {
      return this.copy.id && this.disableOriginalsWithId;
    },
  },
  methods: {
    getErrors(errorPath) {
      return this.formatErrors.filter(error => error.errorPath === errorPath);
    },
    addRow() {
      this.$emit("addRow");
    }
  },
  mounted() {
    this.$emit("input", this.copy);
    if (!this.value.originals) {
      this.$refs.originals.focus();
    }
  }
};
</script>
