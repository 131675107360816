<template>
  <div :class="classes">
    <template v-if="side.length">
      <span v-for="finish in side" :key="finish.id + finish.side" :title="finish.name">
        {{ finish.name }}
      </span>
    </template>
    <span v-else>
      {{ $t('finishes.empty') }}
    </span>
  </div>
</template>
<script>
export default {
  props: ["side", "name", "errors"],
  computed: {
    classes() {
      return {
        [this.name]: true,
        "is-danger": this.errors.includes(this.name)
      };
    }
  }
};
</script>
