<template>
  <span
    @click="$emit('click', { date: day.date, state: classes })"
    :class="classes"
  >
    {{ day.day }}
  </span>
</template>

<script>
import { isSameDay, getISODay, parseISO } from "date-fns";

export default {
  props: {
    day: Object,
    stateList: Object
  },
  computed: {
    classes() {
      let selected = isSameDay(this.day.date, this.stateList.selectedDate);
      let classes = {};

      for (let state in this.stateList) {
        /* All state objects can have six properties
          from: All dates after a certain date, eq: 01-04-17
          to: All dates up to a certain date, 30-04-17
          between: All dates between two dates, eq: 01-04-17 / 30-04-17
          outside: All Dates outside two dates, eq: 01-04-17 / 30-04-17
          days: All days in a week, eq: [6,7] ( Saturday, Sunday)
          dates: Defined dates, eq: 01-04-17, 16-04-17, 24-04-17
        */
        if (this.isDefined(this.stateList[state])) {
          classes[state] = this.isSet(this.stateList[state]);
        }
      }

      return {
        ...classes,
        selected
      };
    }
  },
  methods: {
    isSet(state) {
      let active = false;
      const date = this.day.date;

      /*
       * TODO:
       * Check if we don't have between and outside
       * To and from are disregarded if also given
       */
      if (this.isDefined(state.between)) {
        state.from = new Date(state.between[0]);
        state.to = new Date(state.between[1]);
      } else if (this.isDefined(state.outside)) {
        state.from = new Date(state.between[1]);
        state.to = new Date(state.between[0]);
      }

      /* First check if both from and to are specified. If so then check if
       * one is higher than the other and return the active if so
       * This is done to make sure the outside
       */
      if (this.isDefined(state.from) && this.isDefined(state.to)) {
        if (state.from > state.to) {
          active = date >= state.from || date <= state.to;
        } else {
          active = date >= state.from && date <= state.to;
        }
      } else if (this.isDefined(state.to)) {
        active = date < new Date(state.to);
      } else if (this.isDefined(state.from)) {
        active = date >= new Date(state.from);
      }

      /* Days are quite specific, so let's say you want a range between the first
       * of January and the last of january, but you don't want any saturday or sunday
       * You are going to use the days property.
       */

      if (this.isDefined(state.days) && !active) {
        active = state.days.indexOf(getISODay(date)) !== -1;
      }

      /* Dates are the most specific so they triumph everything */
      if (this.isDefined(state.dates) && !active) {
        state.dates.forEach(date => {
          if (active === false) {
            active = isSameDay(this.day.date, parseISO(date));
          }
        });
      }

      return active;
    },
    isDefined(prop) {
      return typeof prop !== "undefined" && prop;
    }
  }
};
</script>

<style lang="scss">
@import "~vars";

.vuedp-date-picker__calendar .cell {
  &.combi {
    background-color: $cyan;
    color: $white;
  }
  &.contact:not(.disabled) {
    background-color: $danger;
    color: $white;
  }

  &.speed {
    background-color: $yellow;
    color: $white;
  }
}
</style>
