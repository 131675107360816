<template>
  <div class="container is-fluid">
    <form class="cart-wrapper is-desktop columns is-centered" v-if="cart.length > 0" @submit.prevent>
      <div class="cart-overview column is-7-fullhd">
        <cart-view class="box"></cart-view>
        <div class="checkout-row">
          <div class="button-container-left">
            <button class="checkout-button button is" @click="makeOrder('quote')">
              {{ $t('checkout.quote') }}
            </button>
            <button class="checkout-button button" @click="makeOrder('template')">
              {{ $t('checkout.template') }}
            </button>
          </div>
          <div class="button-container-right">
            <button class="checkout-button button is-primary" @click="makeOrder('order')">
              {{ $t('checkout.order') }}
            </button>
          </div>
        </div>
      </div>
      <order-form class="column is-4-desktop" :products="[...cart]" v-model="form" />
    </form>
    <div class="columns" v-else>
      <h1 class="title">{{ $t('checkout.empty') }}</h1>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { validate } from "../utilities/validators";
import * as types from "../store/mutation-types.js";
import CartView from "../components/Checkout/CartView";
import OrderForm from "../components/Checkout/OrderForm";
import orderSchema from "../schemas/order.schema.json";

export default {
  name: "Checkout",
  data() {
    return {
      states: {}
    };
  },
  computed: {
    ...mapGetters(["cart", "totalPrice", "totalCopies", "order"]),
    form: {
      get() {
        return {
          contact: this.$store.state.order.contact,
          details: this.$store.state.order.details
        };
      },
      set(object) {
        this.$store.commit(
          object.model === "contact"
            ? types.UPDATE_CONTACT
            : types.UPDATE_ORDER,
          object.value
        );
      }
    }
  },
  methods: {
    async makeOrder(type) {
      try {
        validate(orderSchema, this.order);
        this.$store.commit(types.SET_LOADING, true);
        if (type == 'quote' || type == 'template') {
          let details = this.$store.state.order.details;
          details.type = type;
          this.$store.commit(types.UPDATE_ORDER, details);
        }
        let order = await this.$http.post("/order", this.order);
        this.$store.commit(types.SET_LOADING, false);
        this.$store.commit(types.REMOVE_FROM_CART);
        this.$store.commit(types.EMPTY_ORDER);
        this.$router.push({
          name: "confirmed",
          query: { orders: order.data.map(order => order.id) }
        });
      } catch (errors) {
        if(errors.hasOwnProperty(0)){
          errors.forEach(error => {
            this.$toasted.error(error.parentSchema.title + " " + error.message);
          });
        }
        else{
          this.$store.commit(types.SET_LOADING, false);
          if(errors.response.status == 405){
            this.$toasted.error(errors.message);
            return;
          }
          this.$toasted.error(this.$t('checkout.error'));
        }
      }
    }
  },
  components: {
    OrderForm,
    CartView
  }
};
</script>
<style lang="scss">
@import "~vars";
@import "~bulma/sass/utilities/mixins";
$maxCheckoutWidth: 1000px;

@include until($desktop) {
  .cart-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
}

.total-prints,
.total {
  padding: 2rem 0;
}

.cart-items {
  border-bottom: 1px solid #000;
}

.cart-view {
  border: 1px solid #ccc;
  max-width: $maxCheckoutWidth;
}

.checkout-row {
  max-width: $maxCheckoutWidth;
  display: flex;
  justify-content: space-between;

  .checkout-button{
    padding: 1rem;
  }

}
</style>
