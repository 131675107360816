<template>
  <div class="container is-fluid">
    <div class="columns is-multiline">
      <div class="column is-2-widescreen is-12-tablet">
        <ul class="catalog__top-chapters">
          <li v-for="({ name, icon }, key) in topChapters" :key="key">
            <router-link :to="{name: 'selector', params: { type: name} }" v-if="catalog[name].length > 0" active-class="is-selected" class="button is-text assortment" exact>
              <span class="icon is-small">
                <svg-icon classes="small" :icon="icon" />
              </span>
              <span>{{ $t('selector.' + name) }}</span>
            </router-link>
          </li>
        </ul>
        <hr>
        <ul class="catalog__lower-chapters">
          <li v-for="({ name, icon }, key) in lowerChapters" :key="key">
            <router-link :to="{name: 'selector', params: { type: name} }" v-if="catalog[name].length > 0" active-class="is-selected" class="button is-text assortment" exact>
              <span class="icon is-small">
                <svg-icon classes="small" :icon="icon" />
              </span>
              <span>{{ $t('selector.' + name) }}</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="column">
        <category v-if="type === 'catalog'" key="catalog" :data="catalog.catalog"/>
        <category
          v-if="type === 'personal'"
          key="personal"
          :data="catalog.personal"
        />
        <flags v-if="type === 'flags'" :data="catalog.flags" key="flags"/>
        <flags v-if="type === 'fence_banners'" :data="catalog.fence_banners" key="fence_banners" />
        <category v-if="type === 'adhesive'" :data="catalog.adhesive" key="adhesive" :only-first-level-icon="true" />
        <category v-if="type === 'vsigns'" :data="catalog.vsigns" key="vsigns" />
        <category v-if="type === 'frames'" :data="catalog.frames" key="frames" />
      </div>
    </div>
    <product-info class="product-info-tab" />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Vevent from "@/utilities/Vevent";

import { topChapters, lowerChapters } from "../store/modules/catalog/chapters";

import ProductInfo from "../components/Selector/ProductInfo";
import Category from "../components/Selector/Category";
import Flags from "../components/Selector/Flags";

export default {
  name: "Selector",
  props: {
    type: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      topChapters,
      lowerChapters
    }
  },
  computed: {
    ...mapState(["catalog"]),
    ...mapGetters(["currentPrint"]),
  },
  components: {
    ProductInfo,
    Category,
    Flags
  },
  beforeRouteEnter(to, from, next) {
    let printer = from.matched.findIndex(match => ["copies", "finishes"].includes(match.name));
    next(vm => {
      if (printer !== -1 && vm.currentPrint.complete === false) {
        vm.$store.dispatch("removeCurrentPrint");
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    Vevent.fire('product-info', false);
    next();
  }

};
</script>

<style lang="scss">
@import "~vars";
@import "~bulma/sass/utilities/mixins";
.buttons.categories {
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.category-list {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.assortment.button {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.icon svg.small {
  width: 1rem;
}

.buttons.categories > .button {
  position: unset;
}

.button.is-text.is-selected h2 {
  font-weight: bold;
}

@include mobile() {
  .category.not-opened {
    display: none;
  }
}

@include tablet() {
  .entry-category {
    max-height: 250px;
    width: 100%;
  }

  .not-opened {
    max-width: 250px;
  }

  .category:not(.entry-category) {
    .slide {
      // transform: translate(-70px);
    }

    .category-list {
      position: absolute;
      top: 0;
      left: 270px;
      transition: transform 0.1 ease;
    }
  }
}

@include desktop() {
  .entry-category {
    max-height: 210px;
  }
}

@include widescreen() {
  .entry-category {
    max-height: inherit;
    overflow: inherit;
  }
}

.product {
  &-enter-active,
  &-leave-active {
    transition: all 0.2s ease-in;
    position: absolute;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateX(-250px);
  }
}
</style>
