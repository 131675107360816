<template>
  <div class="help-button" @mouseleave="startTimer" @mouseover="removeTimer">
    <div
      class="dropdown is-up is-right"
      :class="{ 'is-active': open }"
      @click="open = !open"
    >
      <div class="dropdown-trigger">
        <button
          class="icon is-medium is-size-3"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
        >
          <svg-icon icon="phone" />
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <a href="mailto:info@bigimpact.com" class="dropdown-item">
            <span class="icon is-small"> <svg-icon icon="envelope" /> </span>
            info@bigimpact.com
          </a>
          <hr class="dropdown-divider" />
          <a href="tel:+31 40 266 2555" class="dropdown-item">
            <span class="icon is-small"> <svg-icon icon="phone" /> </span>
            +31 40 266 2555
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      open: false,
      timer: undefined,
    };
  },
  methods: {
    startTimer() {
      this.timer = setTimeout(() => {
        this.open = false;
      }, 500);
    },
    removeTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = undefined;
    },
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 768px) {
  .help-button {
    bottom: 4rem !important;
  }
  }

.help-button {
  position: fixed;
  right: 1rem;
  bottom: 2rem;
}
</style>