<template>
  <div>
    <h2 class="is-size-7">{{ $t('selector.frames') }}</h2>
    <table class="table copies__view" :class="{'is-narrow': narrow}">
      <thead>
        <tr>
          <th class="truncate__frame">{{ $t('frames.type') }}</th>
          <th class="is-narrow">{{ $t('copies_short.quantity') }}</th>
          <th class="is-narrow">{{ $t('copies_short.dimensions') }}</th>
          <th class="truncate__frame" :title="$t('frames.base')">{{ $t('frames.base') }}</th>
          <th class="truncate__frame" :title="$t('frames.support')">{{ $t('frames.support') }}</th>
          <th class="truncate__frame" :title="$t('frames.wallmount')">{{ $t('frames.wallmount') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="frame in frames" :key="frame.height + frame.width + frame.originals + frame.quantity">
          <td class="truncate__frame" :title="$store.getters.getFrameName(frame.id)">{{ $store.getters.getFrameName(frame.id) }}</td>
          <td>{{ frame.sizes.pieces }}</td>
          <td>{{ frame.sizes.width }}x{{ frame.sizes.height }}</td>
          <td class="truncate__frame" :title="baseName(frame.id, frame.base)">{{ baseName(frame.id, frame.base) }}</td>
          <td><input type="checkbox" :checked="frame.support" readonly disabled></td>
          <td><input type="checkbox" :checked="frame.wallmount" readonly disabled></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Frames-View",
  props: {
    frames: {
      type: Array,
      required: true,
    },
    narrow: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    baseName(frameId, baseId) {
      if (baseId) {
        return this.$store.getters
          .getProductFinishes(frameId)
          .filter(finish => finish.level !== 111)
          .find(finish => finish.finishing_id === baseId).name;
      }

      return undefined;
    },
  }
}
</script>

<style lang=scss>
  .copies__view {
    font-size: 0.9rem;
    display: inline-table;
  }

  .truncate__frame {
    max-width: 7rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: lowercase;
  }
</style>