<template>
  <div class="section loading">
    <div class="loader-position" :class="classList">
      <div id="holiday" v-if="isHolidays()">
        <!-- add holiday image or gif -->
      </div>
      <div id="loader" v-else>
        <div class="dot" />
        <div class="dot" />
        <div class="dot" />
        <div class="dot" />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  props: {
    value: Boolean,
    position: {
      type: String,
      default: "center"
    }
  },

  data() {
    return {
      loadingText: "Loading... point",
      tl: false
    };
  },

  methods: {
    closeAnimation() {
      return new Promise(resolve => {
        this.tl = gsap.timeline({
          onComplete: function() {
            resolve();
          }
        });

        this.tl.set(".dot", { clearProps: "background-color", y: 0 })
          .to('#loader', { autoAlpha: 1, scale: 1.3, ease: 'none', duration: 0.3 })
          .to('.dot', { y: 20, autoAlpha: 1, ease: 'back.inOut', stagger: 0.05})
          .to('#loader', { y: -150, autoAlpha: 0, ease: 'back.inOut', duration: 0.3 }, '+=0.3')
          .to('.section.loading', { autoAlpha: 0, duration: 0.2 }, '-=0.1');
      });
    },
    startAnimation() {
      return new Promise(resolve => {
        this.tl = gsap.timeline({
          repeat: -1,
          repeatDelay: 0.1,
          onComplete: function() {
            resolve();
          }
        })

        this.tl
          .set('.dot', { backgroundColor: 'transparent', autoAlpha: 0 })
          .to('.dot', { y: 25, autoAlpha: 1, duration: 0.5, ease: 'back.inOut', stagger: 0.08})
          .fromTo('#loader', { scale: 1.3  }, { scale: 1, autoAlpha: 0, ease: 'none', duration: 0.5 })
      });
    },
    garbage() {
      this.tl.kill();
      this.tl = false;
    },
    isHolidays() {
      const currentDate = new Date();
      return (
        (currentDate.getMonth() === 11 && currentDate.getDate() > 6) ||
        (currentDate.getMonth() === 0 && currentDate.getDate() < 6)
      );
    }
  },

  computed: {
    positionClass() {
      return "position-" + this.position;
    },
    classList() {
      return {
        [this.positionClass]: true,
        "is-holidays": this.isHolidays()
      };
    }
  },

  mounted() {
    if (this.tl !== false) {
      this.garbage();
    }
    this.startAnimation();
  },

  watch: {
    value(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.garbage();
        // garbage
        this.closeAnimation().then(() => {
          this.garbage();
          this.$emit("close");
        });
      }

      if (oldVal === false && newVal === true) {
        this.startAnimation();
      }
    }
  }
};
</script>

<style lang="scss">
@import "~vars";

.loading {
  position: relative;
  min-width: 400px;
  min-height: 400px;
  padding: 7rem;
  display: flex;
}

#loader {
  display: flex;
  justify-content: center;
}

.loader-position:not(.is-holidays) {
  position: absolute;
  width: 200px;
  height: 50px;

  &.position-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.position-right {
    top: 50%;
    right: 0;
    transform: translate(-50, 0);
  }

  &.position-right-bottom {
    bottom: 0;
    right: 0;
  }

  #holiday {
    width: 300px;
    height: 250px;
  }
}

.loader-position.is-holidays {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  max-width: 350px;
}

#loader .dot {
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 6px;
  border: 2px solid #000;
  background-color: $black;

  &:first-child {
    border-color: $cyan;
    background-color: $cyan;
  }

  &:nth-child(2) {
    border-color: $magenta;
    background-color: $magenta;
  }

  &:nth-child(3) {
    border-color: $background-yellow;
    background-color: $background-yellow;
  }
}
</style>
