<template>
  <form class="checkout-form" v-on:submit.prevent>
    <div class="field">
      <div class="control">
        <div class="field">
          <date-picker
            v-if="deliveryDate"
            class="control"
            style="min-width: 300px;"
            v-model="deliveryDate"
            :inputClass="datePickerClasses"
            :focus="true"
            :states="states"
            :label="$t('order_form.delivery_date')"
          />
          <div v-if="!deliveryDate" class="button is-borderless is-loading" />
        </div>
      </div>
      <div v-if="datePickerClasses == 'input is-warning' && !deliveryDateError " class="help is-warning">
        <p>Externe koerier</p>
        <p>
          De door u gekozen deadline is standaard niet mogelijk.
          Zodra de bestanden bij ons binnen zijn, zullen wij de haalbaarheid
          bekijken en evt. contact met u opnemen
        </p>
      </div>
      <div v-if="deliveryDateError " class="help is-danger" >
        <p>Oeps, er gaat iets mis met de transportberekening! Het kan zijn dat er kosten voor een koerier worden doorberekend. Neem graag contact met ons op.</p>
      </div>
      <div v-if="datePickerClasses == 'input is-danger' && !deliveryDateError" class="help is-danger">
        <p>Externe koerier</p>
        <p>
          De door u gekozen deadline is standaard niet mogelijk.
          Zodra de bestanden bij ons binnen zijn, zullen wij de haalbaarheid
          bekijken en evt. contact met u opnemen
        </p>
      </div>
      <div v-if="deviatingTime && datePickerClasses == 'input is-default' && !deliveryDateError" class="help is-info">
        <p>
          U heeft gekozen voor een tijdzending let op:
        </p>
        <p>
          hier worden extra kosten voor in rekening gebracht
        </p>

      </div>
    </div>
    <div class="field">
      <div class="control">
        <label class="label">{{ $t("order_form.package") }}</label>
        <div class="select">
          <select v-model="pack">
            <option :value="false">
              {{ $t("order_form.package_option.standard") }}
            </option>
            <option :value="59">
              {{ $t("order_form.package_option.V000097") }}
            </option>
            <option :value="238">
              {{ $t("order_form.package_option.V000503") }}
            </option>
            <option :value="247">
              {{ $t("order_form.package_option.V000552") }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">{{ $tc("order_form.reference", 1) }} </label>
      <div class="control">
        <input
          type="text"
          :class="referenceClass"
          name="reference"
          :placeholder="$tc('order_form.reference', 2)"
          v-model="reference"
        />
      </div>
    </div>
    <div class="field">
      <label class="label">{{ $tc("order_form.remarks") }}</label>
      <div class="control">
        <textarea
          class="input"
          :placeholder="$tc('order_form.remarks', 2)"
          v-model="remark"
        />
      </div>
    </div>
    <div class="field">
      <label class="label">{{ $tc("order_form.purchaser", 1) }}</label>
      <div
        class="dropdown"
        :class="{'is-active': open}"
        v-if="this.$store.state.user.id == 728"
      >
        <div class="dropdown-trigger">
          <button
            class="button"
            @click="open = !open"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
          >
            {{ contactName }} - {{ contactPhone }} - {{ contactEmail }}
          </button>
        </div>
        <div class="dropdown-menu" id="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <div
              class="dropdown-item"
              v-for="user in users"
              :key="user.id"
              @click="updateContact(user)"
            >
              <a class="dropdown-item">{{ user.name }} {{ user.phone }} {{ user.email }}</a>
            </div>
          </div>
        </div>
        <hr>
      </div>

      <div class="control">
        <input
          type="text"
          class="input"
          @submit.prevent
          :placeholder="$tc('order_form.purchaser', 2)"
          name="name"
          v-model="contactName"
          required
        />
      </div>
    </div>
    <div class="field">
      <label class="label">{{ $t("order_form.phone") }}</label>
      <div class="control">
        <input
          type="tel"
          :class="phoneClass"
          placeholder="0123 456 789"
          name="phone"
          v-model="contactPhone"
          required
        />
      </div>
    </div>
    <div class="field">
      <label class="label">{{ $t("order_form.email") }}</label>
      <div class="control">
        <input
          type="email"
          class="input"
          placeholder="user@example.nl"
          name="email"
          v-model="contactEmail"
          required
        />
      </div>
    </div>
    <div class="field">
      <label class="label">{{ $t("order_form.delivery_address") }}</label>
      <div class="field">
        <div class="control">
          <button
            v-if="!pickup"
            class="partnerButton button is-secondary"
            @click.prevent="partners = !partners"
          >
            {{ addressName }}
          </button>
        </div>
      </div>
      <div class="control">
        <label class="checkbox">
          <input type="checkbox" v-model="pickup" />
          {{ $t("order_form.pickup") }}
        </label>
      </div>
      <div class="control">
        <label class="checkbox">
          <input type="checkbox" v-model="approve_pdf" />
          {{ $t("order_form.approve_pdf") }}
        </label>
      </div>
      <div class="control">
        <label class="checkbox">
          <input type="checkbox" v-model="archive_files" />
          {{ $t("order_form.archive_files") }}
        </label>
      </div>
    </div>
    <modal v-if="partners" v-model="partners" title="Pas gegevens aan">
      <!-- create address component, with property address.
      Only update the property when something different than original address is selected. -->
      <partner-addresses v-model="address" @close="partners = false" />
    </modal>
  </form>
</template>
<script>
import DatePicker from "../DatePicker";
import PartnerAddresses from "../PartnerAddresses";
import Modal from "../Modal";
import { FILL_ADDRESSES } from "../../store/mutation-types";
import jwtDecode from "jwt-decode";
import { addBusinessDays } from 'date-fns'

export default {
  props: {
    products: Array,
    value: Object,
    partner: {
      type: Number,
      required: false,
    },
    edit: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      open: false,
      deliveryDateError: false,
      expedition: {
        deadlines: {},
      },
      partners: false,
      customerId: null,
      users: [],
      addressValue: this.value.details.address || false,
    };
  },
  computed: {
    datePickerClasses() {
      let deadlineClass = " is-default";
      if (
        this.deliveryDate <
        new Date(this.expedition.deadlines.regular_deadlines)
      ) {
        deadlineClass = " is-danger";
      } else if (
        this.deliveryDate > new Date(this.expedition.deadlines.speed_deadlines) && this.deliveryDate <= new Date(this.expedition.deadlines.regular_deadlines).setHours(23,59,59,999)
      ) {
        deadlineClass = " is-warning";
      }

      return "input" + deadlineClass;
    },
    referenceClass() {
      if (this.reference) {
        return "input is-default";
      }
      return "input is-danger";
    },
    phoneClass() {
      if (this.contactPhone) {
        return "input is-default";
      }
      return "input is-danger";
    },
    deviatingTime() {
      return this.deliveryDate ? this.deliveryDate.getHours() !== 17 : false;
    },
    defaultDelivery() {
      return this.$store.state.addresses[0];
    },
    contactName: {
      get() {
        return this.value.contact.name;
      },
      set(value) {
        this.update("name", value);
      },
    },
    addressName() {
      return this.addressValue
        ? `${this.addressValue.name} - ${this.addressValue.street}, ${this.addressValue.city}`
        : `${this.defaultDelivery.name} - ${this.defaultDelivery.street}, ${this.defaultDelivery.city}`;
    },
    address: {
      get() {
        if (this.value.details.hasOwnProperty("address")) {
          return this.value.details.address;
        }
        return this.addressValue;
      },
      set(value) {
        this.update("address", value, "details");
        this.addressValue = value;
      },
    },
    pack: {
      get() {
        return this.value.details.pack || false;
      },
      set(value) {
        this.update("pack", value, "details");
      },
    },
    pickup: {
      get() {
        return this.value.details.pickup;
      },
      set(value) {
        this.update("pickup", value, "details");
      },
    },
    contactPhone: {
      get() {
        return this.value.contact.phone;
      },
      set(value) {
        this.update("phone", value);
      },
    },
    contactEmail: {
      get() {
        return this.value.contact.email;
      },
      set(value) {
        this.update("email", value);
      },
    },
    reference: {
      get() {
        return this.value.details.reference;
      },
      set(value) {
        this.update("reference", value, "details");
      },
    },
    remark: {
      get() {
        return this.value.details.description;
      },
      set(value) {
        this.update("description", value, "details");
      },
    },
    approve_pdf: {
      get() {
        return this.value.details.approve_pdf;
      },
      set(value) {
        this.update("approve_pdf", value, "details");
      },
    },
    archive_files: {
      get() {
        return this.value.details.archive_files || false;
      },
      set(value) {
        this.update("archive_files", value, "details");
      },
    },
    deliveryDate: {
      get() {
        if (this.value.details.delivery_date) {
          return new Date(this.value.details.delivery_date);
        }
        return addBusinessDays(new Date(), 5);
      },
      set(value) {
        if (value instanceof Date) {
          value = value.toISOString();
        }
        this.update("delivery_date", value, "details");
      },
    },
    states() {
      let speed = {},
        contact = {},
        combi = {},
        disabled = { to: new Date(), days: [6, 7] };

      if (this.expedition.hasOwnProperty("deadlines")) {
        let deadlines = this.expedition.deadlines;
        // combi
        combi = { dates: deadlines.combi_deadlines };
        contact = { to: deadlines.speed_deadlines };
        speed = {
          between: [deadlines.speed_deadlines, deadlines.regular_deadlines],
        };
        if (deadlines.disabled) {
          disabled = { ...disabled, ...deadlines.disabled };
        }
      }

      return {
        disabled,
        contact,
        speed,
        combi,
      };
    },
  },
  methods: {
    update(prop, value, model = "contact") {
      let old = this.value[model];
      this.$set(old, prop, value);
      this.$emit("input", {
        model: model === "contact" ? "contact" : "details",
        value: old,
      });
    },
    updateContact(user) {
      this.contactName = user.name;
      this.contactPhone = user.phone;
      this.contactEmail = user.email;
      this.open = false;
    },
  },
  async mounted() {
    let expedition;

    // get customer id from jwt token
    if(this.$store.state.user.id == 728){
      this.customerId = jwtDecode(localStorage.getItem("token")).partner;
  
      await this.$http.get(`/users/${this.customerId}`).then(({ data: { active }}) => {
          this.users = active;
        });
    }

    if (this.partner) {
      const url = `/order/${this.$route.params.orderId}/expedition?hash=${this.$route.query.hash}`;
      expedition = await this.$http.post(url, {
        products: this.products,
        contactUser: this.value.contact,
        details: this.value.details,
      });

      this.$store.commit(FILL_ADDRESSES, expedition.data.addresses);
    } else {
      this.deliveryDateError = false;
      expedition = await this.$http.post(`/expedition`, {
        products: this.products,
        contactUser: this.value.contact,
        details: this.value.details,
      }).catch(error => {
        this.deliveryDateError = true;
        this.$toasted.error("Er is iets misgegaan bij het ophalen van de data");
      })
    }

    this.expedition = expedition.data;
    if(!this.edit){
      this.deliveryDate = new Date(expedition.data.deadlines.suggested_date);
    } 
  },
  components: {
    DatePicker,
    Modal,
    PartnerAddresses,
  },
};
</script>
<style scoped lang="scss">
.partnerButton {
  width: 100%;
  overflow: hidden;
  justify-content: flex-start;
}
</style>
