<template>
  <div class="category-wrapper">
    <div class="breadcrumb">
      <ul>
        <li
          class="arrow"
          v-for="(route, index) in traceRoute"
          :key="index"
        >
          <a href="#" @click.prevent="routeHandler(route.handler, index)">
            <span class="icon is-small">
              <svg-icon icon="chevron-left" />
            </span>
            {{ route.name }}
          </a>
        </li>
      </ul>
    </div>
    <tree
      class="flags"
      :seed="data"
      wrapperTag="div"
      nodeClass="flag-wrapper"
      :transitionGroup="transitionGroup"
      tag="div"
    >
      <template slot-scope="_">
        <product v-if="_.vm.isLeaf" :product="_.node" />
        <span
          v-else-if="onlyFirstLevelIcon && _.vm.level !== 0"
          class="button is-text"
          @click="handleClick(_.vm)"
          :class="{ 'is-selected': _.vm.isOpened }"
        >
          {{ _.node.name }}
        </span>
        <div v-else class="flag" @click="handleClick(_.vm)">
          <div class="app-link--header">
            <figure class="image link-icon">
              <svg-icon viewBox="0 0 100 100" :icon="$t('selector-icons.' + slugify(_.node.name))" fill="#fff" />
            </figure>
          </div>
          <div class="app-link--content">
            <h1 class="subtitle">{{ _.node.name }}</h1>
          </div>
        </div>

      </template>
    </tree>
  </div>
</template>
<script>
import Tree from "../ScopedTree/Tree";
import Product from "./Product";
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    onlyFirstLevelIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      traceRoute: []
    };
  },
  methods: {
    handleClick(vm) {
      console.log(vm.level);
      if (! (this.onlyFirstLevelIcon && vm.level > 0)) {
        this.traceRoute.push({
          name: vm.value.name,
          handler: () => {
            vm.toggle();
            vm.itemSiblings.forEach(node => {
              node.setClasses([]);
            });
          }
        });
      }
      vm.itemSiblings.forEach(node => {
        node.closeChildren();
        node.setClasses(["not-opened"]);
      });
      vm.setClasses([]);
      vm.toggle();
    },
    routeHandler(handler, key) {
      handler();
      this.traceRoute.splice(key);
    },
    slugify(text) {
      return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w-]+/g, "") // Remove all non-word chars
        .replace(/-+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
    }
  },
  computed: {
    transitionGroup() {
      if (this.onlyFirstLevelIcon) {
        return { name: '', class: ['flag-choice', 'first-level-only'] }
      }
      return { name: '', class: ['flag-choice'] }
    }
  },
  components: {
    Tree,
    Product
  }
};
</script>
<style lang="scss">
.flags {
  display: flex;
  position: relative;

  .flag {
    cursor: pointer;
    margin: 0 1rem;
    opacity: 1;
  }

  .flag-choice {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
  }

  .flag-wrapper {
    flex: 0 0 20%;
  }

  > .flag-wrapper.is-opened:not(.end-branch) > .flag-choice {
    width: 100%;
    flex-direction: row;

    &.first-level-only {
      flex-direction: column;
    }

    .first-level-only {
      left: 270px;
    }
  }

  .not-opened,
  .is-opened > .flag {
    opacity: 0;
    transform: translateY(-25%);
    transition: all 0.4s ease-in-out;
    visibility: hidden;
  }

  .first-level-only .flag-wrapper.not-opened {
    opacity: 1;
    visibility: visible;
    transform: none;
}
}
</style>
