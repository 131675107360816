<template>
  <div class="finish-editor">
    <div v-if="normal_finish.length > 0">
      <h1 v-if="value.length > 0">{{ $t('finishes.label')}}</h1>
      <h1 v-else>{{ $t('finishes.empty') }}</h1>

      <finish
        v-for="(location, index) in active"
        :key="location"
        :side="location"
        :finishes="filtered"
        :value="finish(location)"
        :maxLevel="freeLevel"
        @input="processFinish(location, $event)"
        @delete="removeRow(index)"
      />

      <div class="finishes-sort">
        <finish-button
          v-for="(value, type) in matrix"
          :key="type"
          :type="type"
          @click="addFinish"
          :disabled="disabled(type)"
        />
      </div>
    </div>

    <div class="finish-extras" v-if="extras.length >= 0">
      <finish-extra 
        v-for="(extraFinish,index) in extraFinishes" 
        :key="extraFinish.finishing_id" 
        :value="extraFinishes[index]" 
        :finishes="extras" 
        @input="addExtra($event)" 
        @delete="deleteExtra(index,extraFinish)" 
        @update="updateExtra"
      />
    </div>
  </div>
</template>
<script>
import Finish from "./Finish";
import FinishExtra from "./FinishExtra";
import FinishButton from "./FinishButton";
import { extractFinishes, sideCheck } from "../../utilities/helpers";
import { sortBy, isEqual, difference, clone } from "lodash-es";
export default {
  props: {
    value: { type: Array, required: true },
    finishes: { type: Array, required: true },
    product: { type: Object, required: true }
  },
  data() {
    return {
      active: [],
      extraFinishes: [{finishes: [], type: null}],
      matrix: {
        around: [
          "opposite-top",
          "opposite-left",
          "top",
          "right",
          "bottom",
          "left"
        ],
        "opposite-top": ["around", "top", "bottom"],
        "opposite-left": ["around", "left", "right"],
        top: ["around", "opposite-top"],
        right: ["around", "opposite-left"],
        bottom: ["around", "opposite-top"],
        left: ["around", "opposite-left"]
      },
      extra: {
        finishes: [],
        type: null
      }
    };
  },
  computed: {
    filtered() {
      return this.finishes.filter(finish => ![8, 9].includes(finish.level));
    },
    maxLevel() {
      let level = [];
      this.finishes.forEach(finish => {
        if (!level.includes(finish.level) && ![8, 9].includes(finish.level)) {
          level.push(finish.level);
        }
      });
      return level;
    },
    extras() {
      return this.finishes.filter(finish => {
        if (finish.level === 9 || finish.level === 8) {
          if(this.extraExist(finish.finishing_id)){
            return false;
          }
          return finish;
        }
        return false;
      });
    },
    normal_finish() {
      return this.finishes.filter(finish => {
        if (finish.level !== 9 && finish.level !== 8) {
          return finish;
        }
        return false;
      });
    },
    freeLevel() {
      // Get the selected extracted finishes
      const exclude = [8, 9];
      const finishes = this.value.reduce((acc, finish) => {
        acc.push(...extractFinishes(finish, finish.side));
        return acc;
      }, [])
      const selectedLevels = [...new Set(finishes.map(x => x.level))];
      return this.maxLevel.reduce((acc, level) => {
        // find Level in selectedLevels and check if all sides selected
        if (selectedLevels.includes(level)) {
           const check = sideCheck(finishes.filter(x => !exclude.includes(x.level)).filter(x => x.level === level).map(x => x.side));
           if (check.length !== 0) {
             acc.push(level);
           }
        } else {
          // else push the level directly
          acc.push(level);
        }
        return acc;
      }, []);
    },
    disabledSides() {
      // First get the chosen side(s).
      const options = this.active.reduce((matrix, side) => {
        // Find the key in matrix
        let type = this.matrix[side];
        matrix.push(...type);
        return matrix;
      }, []);
 
      return this.freeLevel.length === 0 ? options : [];
    },
    errors() {
      const exclude = [8, 9];
      let finishes = [];
      this.value.forEach(finish => {
       finishes.push(...extractFinishes(finish, finish.side));
      });
      return sideCheck(
        finishes.filter(x => !exclude.includes(x.level)).filter(x => x.level === 1).map(x => x.side)
      );
    }
  },
  watch: {
    errors() {
      this.$emit("error", this.errors);
    }
  },
  methods: {
    deleteExtra(extraIndex,item){
      if(Object.keys(item.finishes).length > 0){
        let valueIndex = this.value.findIndex(finish => {
          return finish.finishing_id == this.extraFinishes[extraIndex].finishes[0].finishing_id;
        });
        if(valueIndex !== -1){
          this.value.splice(valueIndex,1);
          this.extraFinishes.splice(extraIndex,1);
        }
      }
     
    },
    addExtra(val) {
        if (val.finishes.length > 0 && val.type !== null) {
          val.finishes.map(finish => {
            finish.side = val.type;
          });
          this.extraFinishes.push({finishes: [], type: null});
          const finishes = Array.from(this.value);
          finishes.push(...val.finishes);
          this.$emit("input", finishes);
        }
    },
    updateExtra({val,old}){
      if(typeof val !== 'object'){
        // update side
        let index = this.extraFinishes.findIndex(finish => finish.finishes[0].name == old.name);
        let valueIndex = this.value.findIndex(finish => finish.name == old.name);
        this.extraFinishes[index].finishes[0].side = val;
        this.extraFinishes[index].type = val;
        this.value[valueIndex].side = val;
      }else{
        //update finish
        let index = this.extraFinishes.findIndex(finish => finish.finishes[0].name == old.name);
        let valueIndex = this.value.findIndex(finish => finish.name == old.name);
        this.extraFinishes[index].finishes = val;
        this.value[valueIndex] = val[0];
        this.value[valueIndex].side = old.side;
      }
    },
    extraExist(extraId){
      return this.value.some(finish =>{
         return finish.finishing_id == extraId ? true:false;
        });
    },
    finish(location) {
      return this.value.filter(finish => finish.side === location);
    },
    processFinish(location, finishesToAdd) {
      // remove everything that has location and add the new input to the finishes
      let finishes = this.value.filter(x => x.side !== location);
      finishes.push(...finishesToAdd);
      this.$emit("input", finishes);
    },
    addFinish(finish) {
      // New
      if (!this.active.find(side => side === finish)) {
        this.active.push(finish);
        this.disabledSides;
      } else {
        this.$toasted.error("side already exists");
      }
    },
    removeRow(index) {
      this.processFinish(this.active[index], []);
      this.active.splice(index, 1);
    },
    disabled(type) {
      return (
        this.value.find(select => select.side === type && select.level !== 8 && select.level !== 9) ||
        this.disabledSides.includes(type)
      );
    }
  },
  mounted() {
    if (this.value.length > 0 && this.active.length === 0) {
      this.value.forEach(finish => {
        this.active.push(finish.side);
        if (finish.level === 9 || finish.level === 8) {
          this.extra.type = finish.side;
          this.extra.finishes = [finish];
        }
      });
    }
  },
  components: {
    Finish,
    FinishButton,
    FinishExtra
  }
};
</script>
