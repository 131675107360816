import Vue from "vue";
import Router from "vue-router";
import i18n from "./i18n";

import Login from "./views/Login/Login";
import LoginModal from "./views/Login/LoginModal";
import ForgotPassword from "./views/Login/ForgotPassword";
import ResetPassword from "./views/Login/ResetPassword";
import Boot from "./views/Boot";
import Selector from "./views/Selector";
import Printer from "./views/Printer/Printer";
import Copies from "./views/Printer/Copies";
import Finishes from "./views/Printer/Finishes";
import Checkout from "./views/Checkout";
import NotFound from "./views/404";

import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "home" */ "./views/Home.vue"),
      meta: {
        transition: "fade",
        title: "router.welcome"
      }
    },
    {
      path: "/selector/:type",
      component: Selector,
      name: "selector",
      meta: {
        title: "router.order",
        home: true,
        icon: "selector",
        params: $store => ({
          type: $store.state.catalog.personal.length > 0 ? "personal" : "catalog"
        })
      },
      props: true,
    },
    {
      path: "/printer",
      component: Printer,
      beforeEnter: (to, from, next) => {
        if (store.getters.currentProduct === false) {
          next({ name: "selector" });
        }

        next();
      },
      meta: {
        title: "router.configurator"
      },
      children: [
        {
          path: "",
          name: "copies",
          component: Copies,
          meta: {
            title: "router.copies"
          }
        },
        {
          path: "finishes",
          name: "finishes",
          component: Finishes,
          meta: {
            title: "router.finishes"
          }
        }
      ]
    },
    {
      path: "/checkout",
      name: "checkout",
      component: Checkout,
      meta: {
        title: "router.checkout"
      }
    },
    {
      path: "/login",
      component: LoginModal,
      meta: {
        transition: "fade",
        title: "router.login"
      },
      children: [
        {
          path: "",
          name: "login",
          component: Login,
          meta: { login: true }
        },
        {
          path: "/forgot",
          name: "forgot-password",
          component: ForgotPassword,
          meta: { login: true }
        },
        {
          path: "/reset/:token",
          name: "reset-password",
          component: ResetPassword,
          meta: { login: true },
          props: true
        }
      ],
      beforeEnter: (to, from, next) => {
        if (store.getters.auth) {
          next({ name: "home", query: to.query });
        }

        next();
      }
    },
    {
      path: "/overview",
      name: "overview",
      component: () =>
        import(/* webpackChunkName: "overview" */ "./views/Overview.vue"),
      meta: {
        title: "router.overview",
        icon: "overview",
        home: true,
        params: $store => {}
      },
      children: [
        {
          path: "orders",
          name: "orders",
          component: () =>
            import(/* webpackChunkName: "orders" */ "./views/Orders"),
          meta: {
            title: "router.orders"
          }
        },
        {
          path: "quotes",
          name: "quotes",
          component: () =>
            import(/* webpackChunkName: "orders" */ "./views/Orders"),
          meta: {
            title: "router.quotes"
          }
        },
        {
          path: "templates",
          name: "templates",
          component: () =>
            import(/* webpackChunkName: "invoices" */ "./views/Orders"),
          meta: {
            title: "router.templates"
          }
        },
        {
          path: "invoices",
          name: "invoices",
          component: () =>
            import(/* webpackChunkName: "invoices" */ "./views/Invoices"),
          meta: {
            title: "router.invoices"
          }
        },
      ]
    },
    {
      path: "/loading",
      name: "loading",
      component: Boot,
      meta: {
        transition: "fade",
        title: "router.loading"
      },
      beforeEnter: (to, from, next) => {
        if (store.state.booted) {
          next({ name: "home", query: to.query });
        }

        next();
      }
    },
    {
      path: "/settings",
      name: "settings",
      component: () =>
        import(/* webpackChunkName: "settings" */ "./views/Settings"),
      meta: {
        title: "router.settings"
      }
    },
    {
      path: "/confirmed",
      name: "confirmed",
      meta: {
        title: "router.confirmed"
      },
      component: () =>
        import(/* webpackChunkName: "confirmed" */ "./views/Thanks")
    },
    {
      path: "/order/:orderId",
      name: "order",
      component: () => import(/* webpackChunkName: "order" */ "./views/Order"),
      props: true,
      meta: {
        hash: true,
        title: "router.orderId"
      }
    },
    {
      path: "*",
      name: "404",
    component: NotFound,
      meta: {
        title: "router.404"
      }
    }
  ]
});

const title = " | BI order";

router.beforeEach(async (to, from, next) => {
  // Route titles for documents.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);
  if (nearestWithTitle) document.title = i18n.t(nearestWithTitle.meta.title, to.params) + title;

  // Startup not needed for hashed routes
  if (to.meta.login || to.query.token) {
    next();
    return;
  }

  if (!store.getters.startup && !to.meta.hash) {
    try {
      await store.dispatch("authenticate");
    } catch (err) {
      next({ name: "login", query: to.query });
    }
    store.watch(
      (state, getters) => getters.startup,
      value => {
        if (value === true) {
          // router doesn't know the state of the authentication so redirect to first path
          next({ path: to.path });
        }
      }
    );
  }

  if (store.getters.startup) {
    if (store.getters.auth === false && to.name !== "login") {
      next({ name: "login" });
      return;
    }

    if (store.getters.auth) {
      if (store.state.booted === false && to.name !== "loading") {
        next({ name: "loading" });
        return;
      }

      if (store.state.booted === true && to.name === "loading") {
        next({ name: "home" });
        return;
      }
    }
    next();
  }

  if (to.meta.hash && !store.getters.startup) {
    try {
      await store.dispatch("authenticate");
      store.dispatch("boot");
    } catch (err) {
        // Do Nothing
    }
  }

  next();
});

export default router;
