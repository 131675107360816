<template>
  <div class="modal is-active" id="login-modal">
    <div class="modal-background" />
    <transition name="fade" mode="out-in">
      <router-view v-model="email" />
    </transition>
  </div>
</template>
<script>
export default {
  name: "LoginModal",
  data() {
    return {
      email: "",
    }
  }
}
</script>
<style>
#login-modal {
  background-image: url("../../assets/images/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.modal-card-foot {
  justify-content: flex-end;
}
</style>