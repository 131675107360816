import Vue from "vue";
import Vuex from "vuex";
import { validate } from "../utilities/validators";
import { fillHeaderAuth, createPrint } from "../utilities/helpers";
import loginSchema from "../schemas/login.schema.json";
import i18n from "../i18n";
import * as types from "./mutation-types";
import catalog from "./modules/catalog";
import order from "./modules/order";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: false,
    context: "nl",
    booted: false,
    loading: false,
    hasSeenSpecs: true,
    addresses: [],
    finishes: [],
    products: [],
    orders: [],
  },
  getters: {
    auth: state => {
      return Object.keys(state.user).length !== 0;
    },
    startup: state => {
      // User false is not yet checked
      // User object is checked
      return state.user !== false;
    },
    productName: state => id => {
      let product = state.products.find(product => product.id === id);
      return product ? product.name : false;
    },
    getProduct: state => id => {
      let product = state.products.find(product => product.id === id);
      return product;
    },
    getProductFinishes: state => productId => {
      // Create copy from state.
      const finishes = JSON.parse(JSON.stringify(state.finishes));
      const levels = [111, 999];
      return finishes
        .reduce((finishes, finish) => {
          const product = finish.products.find(
            product => product.product_id === productId
          );
          if (product) {
            finish.level = product.level;
            finish.location = product.location;
            finish.sequence = product.sequence;
            delete finish.products;
            finishes.push(finish);
          }

          return finishes;
        }, [])
        .filter(
          finish =>
            levels.includes(finish.level) === false && finish.location === null
        )
        .sort((a, b) => a.sequence - b.sequence);
    }
  },
  mutations: {
    [types.LOGIN_USER](state, user) {
      state.user = user;
      state.context = user.lang.substring(0, 2);
    },
    [types.NEED_LOGIN](state) {
      state.user = {};
    },
    [types.BOOT](state) {
      state.booted = true;
    },
    [types.SET_LOADING](state, status) {
      state.loading = status;
    },
    [types.FILL_FINISHES](state, finishes) {
      state.finishes = finishes;
    },
    [types.FILL_PRODUCTS](state, products) {
      state.products = products;
    },
    [types.ADD_ORDERS](state, orders){
      state.orders = orders;
    },
    [types.FILL_ADDRESSES](state, addresses) {
      state.addresses = addresses;
    },
    [types.HAS_SEEN_SPECS](state, hasSeenSpecs) {
      state.hasSeenSpecs = hasSeenSpecs
    }
  },
  actions: {
    async authenticate(context) {
      let failed = false;

      // Do we have a token?
      if (fillHeaderAuth() !== "") {
        // Yes, so refresh the token and fill data
        try {
          let refresh = await Vue.http.get("auth/refresh");
          context.dispatch("fillAuth", {
            user: {
              ...refresh.data.user,
              ...{ settings: refresh.data.settings }
            },
            token: refresh.data.token
          });
        } catch (error) {
          fillHeaderAuth("");
          failed = new Error("token");
        }
      } else {
        // Throw token error for redirect to login
        failed = new Error("token");
        fillHeaderAuth("");
      }

      // Make sure loading is false
      context.commit(types.SET_LOADING, false);

      if (failed instanceof Error) {
        fillHeaderAuth("");
        context.commit(types.NEED_LOGIN);
        throw failed;
      }

      return true;
    },
    fillAuth(context, { user, token }) {
      fillHeaderAuth(token);
      // File order default settings with user settings;
      if (user.settings) {
        const order = context.state.order.details;
        const settings = user.settings;
        order.pickup = user.settings.pickup_default;
        order.approve_pdf = user.settings.approve_pdf;
        context.commit(types.UPDATE_ORDER, order);
        context.commit(types.UPDATE_USER_SETTINGS, settings);
      }

      // Log user in
      context.commit(types.LOGIN_USER, user);
      context.commit(types.UPDATE_CONTACT, {
        name: context.state.user.name,
        email: context.state.user.email,
        phone: context.state.user.phone
      });
      i18n.locale = context.state.context;
    },
    async login(context, credentials) {
      // Validate else throw error
      try {
        validate(loginSchema, credentials, context.state.context);
      } catch (error) {
        throw error;
      }

      let call;
      // Async login
      switch (credentials.type) {
        case "partner":
          try {
            call = await Vue.http.get(`/auth/partner/${credentials.partner}`);
          } catch (error) {
            throw Error(error.response.data.message);
          }
          break;
        case "token":
          context.dispatch("fillAuth", {
            user: false,
            token: credentials.token
          });
          try {
            call = await Vue.http.get(`/auth/refresh`);
          } catch (error) {
            throw Error(error.response.data.message);
          }
          break;
        case "password":
        default:
          try {
            fillHeaderAuth("");
            call = await Vue.http.post("/auth/login", credentials);
          } catch (error) {
            throw Error(error.response.data.message);
          }
      }

      context.dispatch("fillAuth", {
        user: { ...call.data.user, ...{ settings: call.data.settings } },
        token: call.data.token
      });

      if (call.status === 206) {
        return call;
      }

      // User logged in
      return true;
    },
    logout(context) {
      context.commit(types.NEED_LOGIN);
      // Invalidate old token
      Vue.http.delete("auth/logout");
      // set token to empty
      fillHeaderAuth("");
    },
    async boot(context) {
      const fillCatalog = Vue.http.get("/catalog").then(catalog => {
        Object.keys(catalog.data).forEach(type => {
          const mutation = types["FILL_" + type.replace("-", "").toUpperCase()];
          if (mutation === "FILL_CATALOG") {
            context.commit(mutation, catalog.data[type]);
          } else {
            context.commit(mutation, catalog.data[type].length > 0 ? catalog.data[type][0].children : []);
          }
        });
      });

      const fillProducts = Vue.http.get("/products").then(products => {
        context.commit(types.FILL_PRODUCTS, products.data);
      });

      const fillFinishes = Vue.http.get("/finishes").then(finishes => {
        context.commit(types.FILL_FINISHES, finishes.data);
      });

      const fillAddresses = Vue.http.get("/addresses").then(addresses => {
        context.commit(types.FILL_ADDRESSES, addresses.data);
      });

      const checkSpecs = Vue.http.get('/specs/check').then(specs => {
        context.commit(types.HAS_SEEN_SPECS, specs.data.seen_specs);
      });

      await Promise.all([fillCatalog, fillProducts, fillFinishes, fillAddresses]);

      context.commit(types.BOOT);
    },
    createPrint(context, product) {
      context.commit(types.CREATE_PRINT, createPrint(product));
    },
    addCopies(context, copies) {
      context.commit(types.ADD_COPIES, copies);
      if (!context.getters.needFinish) {
        context.commit(types.COMPLETE_PRINT);
      }
    },
    addCopiesCopyOrder(context, copies) {
      context.commit(types.ADD_COPIES, copies);
      context.commit(types.COMPLETE_PRINT);
      
    },
    addFinishes(context, finishes) {
      context.commit(types.ADD_FINISHES, finishes);
      context.commit(types.COMPLETE_PRINT);
    },
    unsetActive(context) {
      context.commit(types.UNSET_ACTIVE);
    },
    removeFromCart(context, print) {
      context.commit(types.REMOVE_FROM_CART, print);
    },
    removeCurrentPrint(context) {
      context.commit(types.REMOVE_CURRENTPRINT);
    },
    addPriceToPrint(context, data) {
      context.commit(types.ADD_PRICES, data);
    },
    addFrames(context, frames) {
      context.commit(types.ADD_FRAMES, frames);
    },
    updateAddresses(context, addresses){
      this.addresses = addresses;
      context.commit(types.FILL_ADDRESSES,addresses);
    }
  },
  modules: {
    catalog,
    order
  }
});
