import store from "../../store/index.js";
import { parseISO, format as formatter } from "date-fns";

export default function(
  value,
  format = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  }
) {
  if (!(value instanceof Date)) {
    value = parseISO(String(value));
  }

  if (typeof format === 'string') {
    return formatter(value, format);
  }

  return new Intl.DateTimeFormat(store.state.context, format).format(value);
}
