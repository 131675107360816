<template>
  <div>
    <copies-editor-mobile v-model="copy.copies" :product="product" v-if="el.is['is-small']" />
    <div v-else>
      Aantallen
      <copy-editor v-model="copy.copies" :product="product"  />
    </div>
    <div v-if="needFinish">
      <hr />
      <finish-editor
        v-model="copy.finishes"
        :finishes="finishes"
        :product="product"
        @error="finishErrors = $event"
      />
    </div>
    <hr>
    <button class="button is-primary bg-cyan c-white" @click="save">
      Opslaan
    </button>
  </div>
</template>
<script>
import CopyEditor from "../Copies/CopiesEditor";
import FinishEditor from "../Finishes/FinishEditor";
import clone from "lodash-es/cloneDeep";
import { validate } from "../../utilities/validators";
import copiesSchema from "../../schemas/copies.schema.json";
import CopiesEditorMobile from "../Copies/CopiesEditorMobile";
import responsiveMixin from "../../utilities/responsiveMixin";

export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      copy: clone(this.value),
      finishErrors: []
    };
  },
  computed: {
    product() {
      return this.$store.getters.getProduct(this.copy.product_id);
    },
    finishes() {
      return this.$store.getters.getProductFinishes(this.product.id);
    },
    needFinish() {
      const levels = [111, 999];
      // if product has default finishes, we don't need
      let defaults = this.finishes.find(finish => {
        return finish.location !== null &&
          levels.findIndex(level => level === finish.level) === -1
          ? finish
          : false;
      });

      return defaults !== undefined || this.finishes.length > 0;
    }
  },
  mixins: [responsiveMixin],
  methods: {
    save() {
      try {
        validate(copiesSchema, this.copy.copies);

        if (this.finishErrors.length > 0) {
          throw this.finishErrors.map(finish => ({
            message: this.$t("finishes." + finish) + " " + this.$t("errors.missing_finish"),
            params: { allowedValue: "" }
          }));
        }
        this.$emit("input", this.copy);
      } catch (errors) {
        errors.forEach(error => {
          this.$toasted.error(error.message + " " + error.params.allowedValue);
        });
      }
    }
  },
  components: {
    CopyEditor,
    FinishEditor,
    CopiesEditorMobile
  }
};
</script>
