<template>
  <div>
    <div class="copies-header" v-if="!el.is['is-small']">
      <span>{{ $t('printer.details') }}</span>
      <div class="excel-section">
        <div class="file is-primary">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              accept=".xlsx"
              name="resume"
              @change="importSheet"
            />
            <span class="file-cta">
              <span class="file-label">
                {{ $t('printer.import_excel') }}
              </span>
            </span>
          </label>
        </div>
        <a
          href="/BigImpact-productspecificatie.xlsx" class="button is-text"
        >{{ $t('printer.download_template') }}</a
        >
      </div>
    </div>
    <copies-editor-mobile v-model="copies" :product="product" :errors="errors" v-if="el.is['is-small']" />
    <copies-editor v-model="copies" :product="product" :errors="errors" v-else/>
    
    <div class="total-counter">
      <strong>{{ $t('printer.counter_label') }}</strong>
      <span> {{ $tc('printer.counter', size) }}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { createCopy } from "../../utilities/helpers";
import { validate } from "../../utilities/validators";
import copiesSchema from "../../schemas/copies.schema.json";
import plusframeSchema from "../../schemas/plusframecopies.schema.json";
import CopiesEditor from "../../components/Copies/CopiesEditor";
import CopiesEditorMobile from "../../components/Copies/CopiesEditorMobile";
import responsiveMixin from "../../utilities/responsiveMixin";

let readXlsxFile = null;

import(/* webpackChunkName: "read-excel-file" */ 'read-excel-file').then(excelReader => {
   readXlsxFile = excelReader.default;
});

export default {
  data() {
    return {
      copies: [this.createCopy()],
      errors: []
    };
  },
  mounted() {
    if (this.currentCopies) {
      this.copies = this.currentCopies;
    }
  },
  computed: {
    ...mapGetters({
      print: "currentPrint",
      product: "currentProduct",
      currentCopies: "currentCopies"
    }),
    size() {
      return this.copies.reduce((acc, copy) => {
        if (copy.sides === 2) {
          return acc += 1 * copy.quantity;
        }
        return acc += copy.originals * copy.quantity;
      }, 0);
    }
  },
  components: {
    CopiesEditor,
    CopiesEditorMobile,
  },
  beforeRouteLeave(to, from, next) {
    // check if filled
    if (
      to.name === "finishes" ||
      to.name === "basket" ||
      to.name === "checkout"
    ) {
      try {
        if(this.product.name.includes("PlusFrame")){
          validate(plusframeSchema,this.copies);
        }
        else{
          validate(copiesSchema, this.copies);
        }
        
        if (this.size < this.product.min_order_qty) {
          throw [{
              message: "Minimaal besteld product moet totaal: " + this.product.min_order_qty + " zijn",
              params: {allowedValue: "Bestelhoeveelheid"},
              dataPath: "[0].quantity"
          },
          {
              message: "Minimaal besteld product moet totaal: " + this.product.min_order_qty + " zijn",
              params: {allowedValue: "Bestelhoeveelheid"},
              dataPath: "[0].originals"
          }
          ];
        }

        this.$store.dispatch("addCopies", this.copies);
        next();
      } catch (errors) {
        console.log(errors);
        this.errors = errors;
        // errors.forEach(error => {
          // const
          // const errorMessage = this.$t('validation.copies.')
          // this.$toasted.error();
        // });
      }
    } else {
      next();
    }
  },
  methods: {
    createCopy() {
      return new createCopy(null, this.$store.getters.currentProduct);
    },
    removeEmptyRow() {
      // Check if Row is entirely empty
      this.copies.forEach((copy, index) => {
        if (this.copyIsEmpty(copy)) {
          this.copies.splice(index, 1);
        }
      });
    },
    copyIsEmpty(copy) {
      for (let key in copy) {
        if (
          key === "remark" ||
          key === "id" ||
          key === "complete" ||
          key === "sides" ||
          key === "abaa" ||
          this.$store.getters.currentProduct.fixed_width > 0 ||
          this.$store.getters.currentProduct.fixed_height > 0
        ) {
          continue;
        }
        if (copy[key] !== undefined && copy[key] != "") {
          return false;
        }
      }
      return true;
    },
    importSheet(e) {
      readXlsxFile(e.target.files[0]).then(rows => {
        // Remove first row (names)
        rows.splice(0, 1);
        rows.forEach(row => {
          let copy = this.createCopy();
          copy.originals = Number.parseInt(row[0]);
          copy.quantity = Number.parseInt(row[1]);
          if ([0, null, "0"].includes(this.product.fixed_width))
            copy.width = Number.parseInt(row[2]);
          if ([0, null, "0"].includes(this.product.fixed_width))
            copy.height = Number.parseInt(row[3]);
          copy.remark = row[4] ? String(row[4]) : "";
          this.copies.push(copy);
        });
      });
      this.removeEmptyRow();
    }
  },
  mixins: [responsiveMixin],
};
</script>
<style lang="scss">
.copies-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .excel-section {
    display: flex;
    flex-direction: column;

    .button.is-text {
      padding-left: 2rem;
    }
  }
}

.total-counter {
  margin-top: 3rem;
}

@media screen and (max-width: 922px){
  .total-counter{
    font-size: large;
  }
}
</style>
