<template>
  <div class="container is-fluid">
    <div class="columns">
      <div class="column is-2 column-breadcrumbs">
        <nav class="order-breadcrumbs">
          <ul>
            <li>
              <router-link :to="{ name: 'selector', params: {'type': $store.state.catalog.personal.length > 0 ? 'personal' : 'catalog'} }">
                <span class="icon is-small">
                  <svg-icon icon="box-checked" />
                </span>
                {{ $t('printer.selection') }}
              </router-link>
            </li>
            <li :class="{ 'is-active': $route.name === 'copies' }">
              <router-link :to="{ name: 'copies' }">
                <span class="icon is-small">
                  <svg-icon v-show="!copies" icon="box" />
                  <svg-icon v-show="copies" icon="box-checked" />
                </span>
                <span>{{ $t('printer.details') }}</span>
              </router-link>
            </li>
            <li
              v-if="needFinish"
              :class="{ 'is-active': $route.name === 'finishes' }"
            >
              <router-link :to="{ name: 'finishes' }">
                <span class="icon is-small">
                  <svg-icon icon="box" />
                </span>
                <span>{{ $t('printer.finishes') }}</span>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="column">
        <form @submit.prevent="next" @keydown.enter.prevent>
          <div class="screentitle">
            <strong>{{ product.name }}</strong>
          </div>
          <div class="screen">
            <router-view />
          </div>
          <div class="button-row">
            <button
              type="reset"
              @click="$router.go(-1)"
              class="button is-primary"
            >
              {{ $t('ui.back') }}
            </button>
            <button type="submit" class="button is-primary">{{ $t('ui.next') }}</button>
          </div>
        </form>
      </div>
    </div>
    <cart-modal v-model="checkout" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CartModal from "../../components/CartModal.vue";

export default {
  data() {
    return {
      checkout: false
    };
  },
  methods: {
    next() {
      this.$router.push({ name: this.nextRoute });
    }
  },
  computed: {
    ...mapGetters({
      print: "currentPrint",
      product: "currentProduct",
      finishes: "currentFinishes",
      needFinish: "needFinish",
      copies: "currentCopies"
    }),
    nextRoute() {
      if (this.$route.name === "copies" && this.needFinish) {
        return "finishes";
      }
      return "checkout";
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "checkout" && this.checkout === false) {
      this.checkout = true;
    } else {
      if (this.print.complete && this.checkout) {
        this.$store.dispatch("unsetActive");
      }
      next();
    }
  },
  components: {
    CartModal
  }
};
</script>
<style lang="scss">

.screen{
  padding-top: 2rem;
}

@media (max-width: 1508px) {
  .column.column-breadcrumbs {
    display: none;
  }
}
@media screen and (max-width: 922px) {
  .screentitle{
    font-size: xx-large;
  }

  .button-row{
    display: flex;
    .button{
      flex-grow: 1;
    }
  }

}
</style>
