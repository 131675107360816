<template>
  <div class="modal" :class="{ 'is-active': value }">
    <div class="modal-background" />
    <div class="modal-content">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">{{ $t('cart.added') }}</p>
          <!-- <span role="button" @click="$emit('input', false)" class="card-header-icon" aria-label="more options">
            <span class="icon delete">
            </span>
          </span> -->
        </header>
        <div class="card-content">
          <div class="content">
            <div class="section">
              <h2 class="title">
                {{ productName(lastCartProduct.product_id) }}
              </h2>
              <template v-for="(copy, index) in lastCartProduct.copies">
                <nav class="level" :key="index">
                  <div class="level-item has-text-centered">
                    <div>
                      <p class="heading">{{ $t('copies.originals') }}</p>
                      <p class="title">{{ copy.originals }}</p>
                    </div>
                  </div>
                  <div class="level-item has-text-centered">
                    <div>
                      <p class="heading">{{ $t('copies.quantity') }}</p>
                      <p class="title">{{ copy.quantity }}</p>
                    </div>
                  </div>
                  <div class="level-item has-text-centered">
                    <div>
                      <p class="heading">{{ $t('copies.dimensions') }}</p>
                      <p class="title">{{ copy.width }} x {{ copy.height }}</p>
                    </div>
                  </div>
                </nav>
              </template>
            </div>
          </div>
        </div>
        <footer class="card-footer">
          <router-link
            :to="{ name: 'selector', params: {'type': $store.state.catalog.personal.length > 0 ? 'personal' : 'catalog'} }" class="card-footer-item"
          >{{ $t('cart.continue') }}</router-link
          >
          <router-link
            :to="{ name: 'checkout' }"
            class="card-footer-item bg-cyan c-white"
          >{{ $t('cart.checkout') }}</router-link
          >
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: ["value"],
  computed: {
    ...mapGetters(["lastCartProduct", "productName"])
  }
};
</script>
<style>
.card-footer-item {
  font-weight: 400;
}
</style>
