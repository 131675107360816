import Vue from "vue";
import "./bootstrap";

import App from "./components/App.vue";

import router from "./router";
import store from "./store";
import date from "./utilities/filters/date";
import i18n from "./i18n";

require("./assets/sass/style.scss");

Vue.config.productionTip = false;
Vue.config.devtools = process.env.VUE_APP_DEVTOOLS || false;
Vue.filter("date", date);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
