<template>
  <div v-if="product" class="productinfo box">
    <div class="productinfo-head">
      <button class="icon is-medium" @click="favorite">
        <svg-icon icon="heart" fill="#fff" :classes="{ 'is-favorite': isFavorite }" />
      </button>
      <span role="button" class="delete" @click="product = null" />
    </div>
    <transition name="slide" mode="out-in">
      <div :key="product.id">
        <table class="table is-narrow">
          <tr>
            <th colspan="2">{{ product.name }}</th>
          </tr>
          <tr v-if="product.material">
            <td>{{ $t('product.material') }}:</td>
            <td>{{ product.material }}</td>
          </tr>
          <tr v-if="product.material">
            <td>{{ $t('product.gloss') }}:</td>
            <td>{{ product.gloss }}</td>
          </tr>
          <tr v-if="product.width">
            <td>{{ $t('product.width') }}:</td>
            <td>{{ product.width / 10 }} cm</td>
          </tr>
          <tr v-if="product.density">
            <td>{{ $t('product.density') }}:</td>
            <td>{{ product.density }}</td>
          </tr>
          <tr v-if="product.fire_resistant">
            <td>{{ $t('product.fire_resistant') }}:</td>
            <td>{{ product.fire_resistant }}</td>
          </tr>
        </table>

        <button
          class="button is-medium is-primary"
          @click="createPrint(product.id)"
        >
          {{ $t('product.choose') }}
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  ADD_PRODUCT_TO_PERSONAL,
  DELETE_PRODUCT_TO_PERSONAL
} from "../../store/mutation-types";
import Vevent from "@/utilities/Vevent";

export default {
  data() {
    return {
      product: false,
      isFavorite: false
    };
  },
  methods: {
    createPrint(productId) {
      this.$store.dispatch("createPrint", productId);
      this.$router.push({ name: "copies" });
    },
    favorite() {
      this.$http.post(`/favorite/${this.product.id}`).then(result => {
        this.isFavorite = result.data.message;
        this.product.is_favorite = result.data.message;

        if (result.data.message === true) {
          this.$store.commit(ADD_PRODUCT_TO_PERSONAL, this.product);
        } else {
          this.$store.commit(DELETE_PRODUCT_TO_PERSONAL, this.product.id);
          this.product = null;
        }
      });
    }
  },
  created() {
    Vevent.listen("product-info", e => {
      this.product = e;
      this.isFavorite =
        this.product.is_favorite ||
        this.$store.state.catalog.personal.find(
          product => product.id === this.product.id
        );
    });
  }
};
</script>

<style lang="scss">
@import "~vars";
@import "~bulma/sass/utilities/mixins";

.is-favorite {
  fill: $cyan;
  color: $cyan;
}

.productinfo.box {
  position: fixed;
  z-index: 9999;
  top: 0;
  overflow: scroll;
  bottom: 0;
  left: 0;
  right: 0;
  // do not show in mobile 
  @include mobile() {
    display: none;
  }

  @include tablet() {
    top: inherit;
    max-height: 250px;
  }

  @include widescreen() {
    left: inherit;
    max-height: none;
    width: 700px;
    height: 350px;
  }

  .productinfo-head {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease-out, opacity 0.3s ease-out;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(25%);
  opacity: 0;
}
</style>
