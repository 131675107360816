<template>
  <form @submit.prevent="resetPassword" class="modal-card">
    <header class="modal-card-head">
      <h1 class="modal-card-title">New Password</h1>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <label for="email" class="label">Email</label>
        <div class="control">
          <input
            type="email"
            class="input"
            id="email"
            name="email"
            v-model="email"
            placeholder="Email"
            :class="{'is-danger': Object.keys(errors).includes('email')}"
            required
            autofocus
          />
        </div>
        <ul v-if="Object.keys(errors).includes('email')" class="help is-danger">
          <li v-for="error in errors['email']" :key="'email' + error">
            {{ error }}
          </li>
        </ul>
      </div>
      <div class="field">
        <label for="password" class="label">Password</label>
        <div class="control">
          <input
            type="password"
            class="input"
            id="password"
            name="email"
            v-model="password"
            placeholder="Password"
            :class="{'is-danger': Object.keys(errors).includes('password')}"
            required
          />
        </div>
        <ul v-if="Object.keys(errors).includes('password')" class="help is-danger">
          <li v-for="error in errors['password']" :key="'password' + error">
            {{ error }}
          </li>
        </ul>
      </div>
      <div class="field">
        <label for="password_confirmation" class="label">Confirm password</label>
        <div class="control">
          <input
            type="password"
            class="input"
            id="password_confirmation"
            name="password_confirmation"
            v-model="password_confirmation"
            placeholder="Password Confirmation"
            required
          />
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button name="login" class="button is-primary">
        Reset Password
      </button>
      <router-link :to="{ name: 'login' }" class="td-underline ml-auto">Cancel</router-link>
    </footer>
  </form>
</template>
<script>
export default {
  name: "Forgot-Password",
  props: {
    value: {
      required: true,
      type: String
    },
    token: {
      required: true
    }
  },
  data() {
    return {
      password: "",
      password_confirmation: "",
      errors: []
    }
  },
  computed: {
    email: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    async resetPassword(e) {
      try {
        let message = await this.$http.post('/auth/reset', {
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          token: this.token
        });

        this.$toasted.success(message.data.status);
        this.$router.push({name: 'login'});
      } catch (e) {
        this.errors = e.response.data;
        if (this.errors.status) {
          this.$toasted.error(this.errors.status);
        }
      }
    }
  }
}
</script>
