import Vue from "vue";
import {
  CREATE_PRINT,
  ADD_PRICES,
  ADD_FRAMES,
  SET_ACTIVE,
  UNSET_ACTIVE,
  REMOVE_CURRENTPRINT,
  ADD_COPIES,
  ADD_FINISHES,
  COMPLETE_PRINT,
  REMOVE_FROM_CART,
  EMPTY_ORDER,
  UPDATE_CONTACT,
  UPDATE_ORDER,
  UPDATE_USER_SETTINGS,
  UPDATE_PRINT
} from "../../mutation-types";
import { createOrderDetails } from "../../../utilities/helpers";

export default {
  state: {
    prints: [],
    contact: {
      name: "",
      phone: "",
      email: ""
    },
    active: false,
    user_settings: {},
    details: createOrderDetails()
  },
  getters: {
    currentPrint: state => {
      return state.prints[state.active] || false;
    },
    currentCopies: (state, getters) => {
      return getters.currentPrint && getters.currentPrint.copies.length > 0
        ? getters.currentPrint.copies
        : false;
    },
    currentFinishes: (state, getters) => {
      return getters.getProductFinishes(getters.currentProduct.id);
    },
    currentProduct: (state, getters, rootState) => {
      return getters.currentPrint
        ? rootState.products.find(
            product => product.id === getters.currentPrint.product_id
          )
        : false;
    },
    needFinish: (state, getters) => {
      return getters.currentFinishes.length > 0;
    },
    lastCartProduct: (state, getter) => {
      return getter.cart.length > 0
        ? getter.cart[getter.cart.length - 1]
        : false;
    },
    cart: state => state.prints.filter(print => print.complete === true),
    order: (state, getters) => {
      return {
        products: [...getters.cart],
        contact: state.contact,
        details: state.details
      };
    },
    calcTotalCopies: () => copies => {
      return copies
        .map(copy => copy.originals * copy.quantity)
        .reduce((a, b) => a + b);
    },
    totalCopies: (state, getters) => {
      return getters.cart.length > 0
        ? getters.cart
            .map(item => getters.calcTotalCopies(item.copies))
            .reduce((sum, item) => sum + item)
        : false;
    },
    totalPrice: (state, getters) => {
      // Prices is set but total not defined
      return getters.cart.reduce((accumulator, cartItem) => {
        if (!cartItem.prices) return false;

        const price = cartItem.prices.product + (cartItem.prices.frames || 0);

        if (accumulator !== false && price !== false) {
          accumulator += price;
          return accumulator;
        }
        return false;
      }, 0);
    }
  },
  mutations: {
    [CREATE_PRINT](state, product) {
      const length = state.prints.push(product);
      state.active = length - 1;
    },
    [ADD_PRICES](state, { printId, prices }) {
      let print = state.prints.find(print => print.uuid === printId);
      print.prices = prices;
    },
    [ADD_FRAMES](state, frames) {
      state.prints[state.active].frames = frames;
    },
    [SET_ACTIVE](state, key) {
      state.active = key;
    },
    [UNSET_ACTIVE](state) {
      state.active = false;
    },
    [REMOVE_CURRENTPRINT](state) {
      state.prints.splice(state.active, 1);
      state.active = false;
    },
    [ADD_COPIES](state, copies) {
      state.prints[state.active].copies = copies;
    },
    [ADD_FINISHES](state, finishes) {
      state.prints[state.active].finishes = finishes;
    },
    [COMPLETE_PRINT](state) {
      state.prints[state.active].complete = true;
      state.details.delivery_date = null;
    },
    [REMOVE_FROM_CART](state, uuid = false) {
      if (uuid) {
        state.prints.splice(
          state.prints.findIndex(print => print.uuid === uuid),
          1
        );
      } else {
        state.prints.splice(
          state.prints.findIndex(print => print.complete === true)
        );
      }
    },
    [EMPTY_ORDER](state) {
      state.details = createOrderDetails();
      if(state.user_settings){
        state.details.approve_pdf = state.user_settings.approve_pdf;
        state.details.pickup = state.user_settings.pickup_default;
      }
    },
    [UPDATE_CONTACT](state, contact) {
      state.contact = contact;
    },
    [UPDATE_ORDER](state, details) {
      state.details = details;
    },
    [UPDATE_USER_SETTINGS](state, settings) {
      state.user_settings = settings;
    },
    [UPDATE_PRINT](state, print) {
      let updateIndex = state.prints.findIndex(
        current => current.uuid === print.uuid
      );
      Vue.set(state.prints, updateIndex, print);
    }
  }
};
