<template>
  <form @submit.prevent="handleLogin" class="modal-card">
    <header class="modal-card-head">
      <h1 class="modal-card-title">Let's Print</h1>
    </header>
    <section class="modal-card-body" v-if="!showPartner">
      <div class="field">
        <label for="email" class="label">Email</label>
        <div class="control">
          <input
            type="email"
            class="input"
            id="email"
            name="email"
            v-model="email"
            placeholder="Email"
            required
            autofocus
          />
        </div>
      </div>
      <div class="field">
        <label for="password" class="label">Password</label>
        <div class="control">
          <input
            type="password"
            class="input"
            id="password"
            name="password"
            v-model="password"
            placeholder="Password"
            required
          />
        </div>
      </div>
    </section>
    <partners-modal
      v-if="showPartner"
      :partners="partners"
      @submit="choosePartner"
    />
    <footer class="modal-card-foot">
      <button name="login" class="button is-primary" v-if="!showPartner">
        Login
      </button>
      <router-link :to="{ name: 'forgot-password' }" class="td-underline ml-auto">Forgot password?</router-link>
    </footer>
  </form>
</template>
<script>
import PartnersModal from "../../components/PartnersModal";

export default {
  name: "Login",
  props: {
    value: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      showPartner: false,
      password: null,
      partners: []
    };
  },
  computed: {
    email: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  async mounted() {
    if (this.$route.query.token) {
      // Authenticate directly
      try {
        await this.$store.dispatch("login", {
          token: this.$route.query.token,
          type: "token"
        });
      } catch (e) {
        console.log(e);
      }

      this.$router.push({ name: "home" });
    }
  },
  methods: {
    async handleLogin() {
      try {
        const auth = await this.$store.dispatch("login", {
          email: this.email,
          password: this.password
        });

        if (auth.status === 206) {
          ``;
          this.partners = auth.data.user.partners;
          this.showPartner = true;
        } else {
          if (this.$route.query.url) {
            this.$router.push(this.$route.query.url);
          } else {
            this.$router.push({ name: "home" });
          }
        }
      } catch (error) {
        if (error instanceof Array && error.length > 0) {
          // Ajv Error
          error.forEach(error => {
            this.$toasted.error(error.dataPath + " " + error.message);
          });
        } else if (
          typeof error === "object" &&
          error.hasOwnProperty("message")
        ) {
          // Api error
          this.$toasted.error(error.message);
        }
      }
    },
    async choosePartner(e) {
      try {
        const auth = await this.$store.dispatch("login", {
          partner: e,
          type: "partner"
        });

        if (auth) {
          if (this.$route.query.url) {
            this.$router.push(this.$route.query.url);
          } else {
            this.$router.push({ name: "home" });
          }
        }
      } catch (error) {
        this.$toasted.error(error.message);
      }
    },
  },
  components: { PartnersModal },
};
</script>