<template lang="">
  <div class="mobile-editor">
    <p class="printdetails">{{ $t('printer.details') }}</p>
    <div class="printrow" v-for="(item, index) in value" :key="index">
      <label class="label"><u>Regel {{index +1}}</u></label>
      <label class="label">Aantallen</label>
      <div class="field has-addons">
        <p class="control">
          <input class="input" v-model.number="value[index].originals" type="text" placeholder="Orginelen">
        </p>
        <p class="control">
          <a class="button is-static">x</a>
        </p>
        <p class="control">
          <input class="input" v-model.number="value[index].quantity" type="text" placeholder="Oplage">
        </p>
      </div>
      <div class="field">
        <div class="help is-danger">
          <div 
            v-for="error in getErrors('originals')"
            :key="error.errorPath + error.keyword +error.errorIndex">
            <p  v-if="index == error.errorIndex">{{ error.message }}</p>
          </div>
        </div>
        <div class="help is-danger">
          <div 
            v-for="error in getErrors('quantity')"
            :key="error.errorPath + error.keyword+ error.errorIndex">
            <p  v-if="index == error.errorIndex">{{ error.message }}</p>
          </div>
        </div>
      </div>
      <label class="label">Afgewerkt formaat (BxH mm)</label>
      <div class="field has-addons">
        <p class="control">
          <input class="input" v-model.number="value[index].width" type="text" placeholder="Breedte">
        </p>
        <p class="control">
          <a class="button is-static">x</a>
        </p>
        <p class="control">
          <input class="input" v-model.number="value[index].height" type="text" placeholder="Hoogte">
        </p>
        <p class="control">
          <a class="button is-static">mm</a>
        </p>
      </div>
      <div class="field">
        <div class="help is-danger">
          <div 
            v-for="error in getErrors('width')"
            :key="error.errorPath + error.keyword + error.errorIndex">
            <p  v-if="index == error.errorIndex">{{ error.message }}</p>
          </div>
        </div>
        <div class="help is-danger">
          <div 
            v-for="error in getErrors('height')"
            :key="error.errorPath + error.keyword +error.errorIndex">
            <p  v-if="index == error.errorIndex">{{ error.message }}</p>
          </div>
        </div>
      </div>
      <div v-if="product.sides == 2">
        <label class="label">Dubbel of enkelzijdig</label>
        <div class="control">
          <label class="button" :class="{ 'bg-cyan': value[index].abaa === 2 }">
            <input
              type="radio"
              name="sides"
              value="2"
              v-model.number="value[index].abaa"
            />
            A/A
          </label>
          <label class="button" :class="{ 'bg-cyan': value[index].abaa === 1 }">
            <input
              type="radio"
              name="sides"
              value="1"
              v-model.number="value[index].abaa"
            />
            A/B
          </label>
        </div>      
      </div>
      <label class="label">Product referentie</label>
      <div class="control">
        <input class="input" v-model="value[index].remark" type="text" placeholder="Product referentie">
      </div>
      <div class="copy-row__buttons">
        <button
          tabindex="-1"
          class="button"
          @click.prevent="duplicateRow(index)"
        >
          Kopie
        </button>
        <button
          tabindex="-1"
          class="button"
          @click.prevent="addRow(index)"
        >
          
          Nieuw
        </button>
        <button
          v-if="copy.length > 1"
          tabindex="-1"
          class="button"
          @click.prevent="removeRow(index)"
        >Verwijder</button>
      </div>
      <hr>
    </div>
  </div>
</template>
<script>
import { createCopy } from "../../utilities/helpers";
import clone from "lodash-es/cloneDeep";
export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    disableOriginalsWithId: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    copy: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    formatErrors() {
      return this.errors.map(error => {
        const errorPath = error.dataPath.substr(error.dataPath.indexOf('.') + 1);
        const errorIndex = error.dataPath.replace(/[^0-9]/g,'');
        const errorMessage = this.$te(`validation.copies.${errorPath}.${error.keyword}`)
          ? this.$t(`validation.copies.${errorPath}.${error.keyword}`)
          : error.message;
        return {
          errorIndex,
          errorPath,
          keyword: error.keyword,
          message: errorMessage,
        }
      })
    },
  },
  methods: {
    addRow() {
      this.value.push(this.createCopy());
    },
    getErrors(errorPath) {
      return this.formatErrors.filter(error => error.errorPath === errorPath);
    },
    createCopy() {
      return new createCopy(null, this.product);
    },
    removeRow(index) {
      this.value.splice(index, 1);
    },
    duplicateRow(index) {
      const row = clone(this.value[index]);
      delete row.id;
      this.value.push(row);
    }
  },
    
}
</script>
<style lang="scss" scoped>
.mobile-editor{
  min-width: fit-content;
}

.field{
  display: flex;
  .help{
    flex: 50%;
  }
}


.printdetails{
  padding-bottom: 2rem;
  font-size: large;
}

.copy-row__buttons{
  padding-top: 0.5rem;
  display: flex;

  .button{
    flex-grow: 1;
    min-width: 6.6rem;
  }
}

.printrow{
  padding-bottom: 1rem;
}

.button.is-static{
  width: 10px;
  background-color: white;
  border: none;
}
.control{
  margin-bottom: 1.5rem;
}

@media screen{
.columns{
  display:inline !important;
}
}
</style>