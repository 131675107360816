<template>
  <svg :class="classes" :viewBox="viewBox" :style="styling" :fill="fill">
    <use
      :xlink:href="'/img/icons.svg#' + icon + '-icon'"
    />
  </svg>
</template>
<script>
export default {
  props: {
    icon: {type: String, required: true},
    classes: {type: [Object, String]},
    fill: {type: String, default: '#000'},
    viewBox: {type: [Boolean, String], default: false},
    styling: {type: [Boolean, String, Object], default: false}
  }
}
</script>
