<template>
  <component
    :is="tag"
    class="modal"
    :class="{ 'is-active': value }"
  >
    <div class="modal-background" />
    <div class="modal-content" :class="contentClass">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">{{ title }}</p>
          <span
            role="button"
            @click="$emit('input', false)"
            class="card-header-icon"
            aria-label="more options"
          >
            <span class="icon delete" />
          </span>
        </header>
        <template v-if="skipCardContent">
          <slot />
        </template>
        <div v-else class="card-content">
          <slot />
        </div>
        <footer class="card-footer">
          <slot v-if="!title.includes('Quick edit')" name="footer" />
        </footer>
      </div>
    </div>
  </component>
</template>
<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
export default {
  props: {
    value: Boolean,
    title: String,
    tag: {
      type: String,
      default: "div"
    },
    contentClass: String,
    skipCardContent: {
      type: Boolean,
      default: false
    }
  },
  created() {
    disableBodyScroll(this.$el);
  },
  destroyed() {
    clearAllBodyScrollLocks();
  }
};
</script>
<style lang="scss">
.modal-content .card {
  overflow: hidden;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  .card-header,
  .card-footer {
    flex-shrink: 0;
  }

  .card-content {
    overflow: auto;
  }
}
</style>
