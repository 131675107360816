<template>
  <button
    @click.prevent="choose"
    class="button is-borderless"
    :disabled="disabled"
    :title="$t('finishes.' + type)"
  >
    <span class="icon is-large">
      <svg-icon :icon="'finish-' + icon" :styling="{ transform: `rotate(${deg})` }" />
    </span>
  </button>
</template>
<script>
export default {
  props: ["disabled", "type"],
  methods: {
    choose() {
      this.$emit("click", this.type);
    }
  },
  computed: {
    deg() {
      switch (this.type) {
        case "opposite-left":
        case "right":
          return "90deg";
        case "bottom":
          return "180deg";
        case "left":
          return "270deg";
        default:
          return "0deg";
      }
    },
    icon() {
      switch (this.type) {
        case "left":
        case "right":
        case "bottom":
        case "top":
          return "side";
        case "opposite-top":
        case "opposite-left":
          return "opposite";
        default:
          return this.type;
      }
    }
  }
};
</script>
