/// Auth Mutations ///
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const BOOT = "BOOT";
export const SET_LOADING = "SET_LOADING";

export const NEED_LOGIN = "NEED_LOGIN";

/// Filling/Booting ///
export const FILL_CATALOG = "FILL_CATALOG";
export const FILL_PERSONAL = "FILL_PERSONAL";
export const FILL_FLAGS = "FILL_FLAGS";
export const FILL_FRAMES = "FILL_FRAMES";
export const FILL_VSIGNS = "FILL_VSIGNS";
export const FILL_FENCEBANNERS = "FILL_FENCEBANNERS";
export const FILL_ADHESIVE = "FILL_ADHESIVE";
export const FILL_CURTAINS = "FILL_CURTAINS";

export const HAS_SEEN_SPECS = "HAS_SEEN_SPECS";

export const FILL_FINISHES = "FILL_FINISHES";
export const FILL_PRODUCTS = "FILL_PRODUCTS";
export const FILL_ADDRESSES = "FILL_ADDRESSES";

export const ADD_PRODUCT_TO_PERSONAL = "ADD_PRODUCT_TO_PERSONAL";
export const DELETE_PRODUCT_TO_PERSONAL = "DELETE_PRODUCT_TO_PERSONAL";

/// Create an Order ///
export const CREATE_PRINT = "CREATE_PRINT";
export const SET_ACTIVE = "SET_ACTIVE";
export const UNSET_ACTIVE = "UNSET_ACTIVE";
export const REMOVE_CURRENTPRINT = "REMOVE_CURRENTPRINT";
export const ADD_COPIES = "ADD_COPIES";
export const ADD_FINISHES = "ADD_FINISHES";
export const ADD_PRICES = "ADD_PRICES";
export const ADD_FRAMES = "ADD_FRAMES";
export const COMPLETE_PRINT = "COMPLETE_PRINT";

// CART
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const UPDATE_PRINT = "UPDATE_PRINT";
export const EMPTY_ORDER = "EMPTY_ORDER";

// Order Data
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
export const ADD_ORDERS = "ADD_ORDERS";
