<template>
  <div class="cart-item" :class="{'has-arrow': shouldCollapse}">
    <div class="cart-item__header">
      <div class="cart-item__header-info" :class="{'cursor-pointer': shouldCollapse}"  @click="openCollapse = !openCollapse">
        <div class="cart-item__icon" v-if="shouldCollapse">
          <i class="icon is-small" :class="{'rotate-90': openCollapse}">
            <svg-icon icon="chevron"/>
          </i>
        </div>
        <h2 class="is-size-6">{{ productName(print.product_id) }},</h2>
        <price :print="print" :recalculate="recalculate" @recalculated="recalculate = true" />
      </div>
      <input
        v-if="$store.getters.cart.length > 1"
        type="text"
        class="input extra_reference"
        :placeholder="$t('order_form.extra_reference')"
        v-model="extra_reference"
      />
      <div class="cart-item__header-buttons">
        <button class="icon is-medium" @click="editOrder">
          <svg-icon icon="pencil" />
        </button>
        <button class="delete is-large" @click="removeFromCart(print.uuid)" />
      </div>
    </div>
    <transition name="fade">
      <div class="cart-item__overview" v-show="!shouldHide">
        <copies-view class="cart-item__copies" :narrow="el.width < 758" :copies="print.copies" />
        <finish-preview v-if="print.finishes.length > 0 && normalFinishes.length > 0" class="cart-item__preview" :finishes="print.finishes" />
        <div class="cart-item__extra" v-else-if="print.finishes.length > 0"> 
          <h1>Extra producten</h1>
          <p v-for="finish in print.finishes" :key="finish.default_code">{{finish.name}}</p>
        </div>
        <frames-view v-if="print.frames && print.frames.length > 0" :narrow="el.width < 758" :frames="print.frames" class="cart-item__frames"/>
      </div>
    </transition>
    <modal
      v-if="editing"
      contentClass="modal__edit-order"
      v-model="editing"
      :title="$t('ui.edit', {subject: productName(print.product_id)})"
    >
      <edit-print :value="print" @input="updatePrint" />
    </modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { UPDATE_PRINT } from "../../store/mutation-types";
import Price from "./Price";
import Modal from "../Modal";
import EditPrint from "../Order/EditPrint";
import FinishPreview from "../Finishes/FinishPreview";
import CopiesView from "./CopiesView";
import FramesView from "./FramesView";
import responsiveMixin from "../../utilities/responsiveMixin"

import clone from "lodash-es/cloneDeep";

export default {
  props: {
    print: {
      type: Object,
      required: true
    },
    shouldCollapse: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      editing: false,
      openCollapse: false,
      recalculate: false,
    };
  },
  computed: {
    ...mapGetters(["productName", "calcTotalCopies"]),
    extra_reference: {
      get() {
        return this.print.extra_reference || null;
      },
      set(value) {
        const edit = clone(this.print);
        edit.extra_reference = value;
      this.$store.commit(UPDATE_PRINT, edit);
      }
    },
    frames() {
      if (this.print.frames) {
        return this.print.frames;
      }
      return false;
    },
    shouldHide() {
      if (this.shouldCollapse && this.openCollapse === false) {
        return true;
      }
      return false;
    },
    totalFrames() {
      if (this.frames) {
        return this.print.frames.reduce((acc, frame) => {
          return acc += frame.sizes.pieces;
        }, 0)
      }
      return false;
    },
      normalFinishes() {
        return this.print.finishes.filter(finish => {
          if (finish.level !== 9 && finish.level !== 8) {
            return finish;
          }
          return false;
        });
    }
  },
  methods: {
    ...mapActions(["removeFromCart"]),
    totalOriginals(copies) {
      return copies.map(x => x.originals).reduce((a, b) => a + b);
    },
    editOrder() {
      this.editing = true;
    },
    updatePrint($event) {
      // find the uuid in cart and update with new data
      this.$store.commit(UPDATE_PRINT, $event);
      this.editing = false;
      this.recalculate = true;
    }
  },
  mixins: [responsiveMixin],
  components: {
    Price,
    Modal,
    EditPrint,
    FinishPreview,
    CopiesView,
    FramesView
  }
};
</script>
<style lang="scss">
.cart-item {
  padding: 0 0 1rem 0;
}
.modal-content.modal__edit-order {
  width: 80%;
  //mobiel and tablet with 100%
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.cart-item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #000;
  padding: 1rem 0;

  .extra_reference {
    max-width: 240px;
    height: 2rem;
    margin: 0 1rem;
    padding: 0;
  }

  &__icon {
    transition: transform 0.2s ease-in-out;
    width: 2rem;
  }

  .cart-item__header {
    display: flex;
    justify-content: space-between;

    &-info {
      display: flex;
      flex: 1 0 auto;
      height: 2rem;
      align-items: center;
    }

    .price {
      margin-left: 1rem;

      .frame-price {
        margin-left: 1rem;
      }
    }

    &-buttons {
      flex: 0 0 auto;
      display: flex;
      justify-content: flex-end;
      height: 2rem;
    }
  }

  .cart-item__overview {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    flex-wrap: wrap;

    &.is-collapsed {
      padding: 0 18px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
    }

    .cart-item__preview {
      width: 300px;
      align-self: flex-start;
    }
    .cart-item__extra{
      padding: 4rem;
      align-self: flex-start;
    }

    .cart-item__copies {
      width: 50%;
      @media (max-width: 758px) {
        width: 100%;
      }
    }

    .cart-item__frames {
      flex: 1 0 100%;
      max-width: 60%;
      margin-top: 1rem;
    }
  }

  &.has-arrow .cart-item__overview {
      padding-left: 2rem;
  }
}

</style>
