<template>
  <div class="finish finish-extra">
    <v-select
      class="finish-select extra"
      :placeholder="$t('finishes.extra')"
      :options="finishes"
      :value="finish"
      @input="change([$event], 'finishes')"
      :clearable="false"
      :disabled="value.finishes.length > 0"
      label="name"
      :tabindex="1"
    />
    <v-select
      v-if="finish && finish.level === 9"
      class="finish-select extra extra-location"
      :value="location"
      @input="change($event.value, 'type')"
      :placeholder="$t('finishes.location')"
      :options="locations"
      :clearable="false"
      :tabindex="1"
    />
    <button class="delete" @click.prevent="$emit('delete',$event)" />
  </div>
</template>
<script>
import vSelect from "vue-select";
export default {
  props: ["finishes",'value'],
  data() {
    return {
     locations: [
        { value: "opposite-top", label: this.$t('finishes.opposite-top') },
        { value: "opposite-left", label: this.$t('finishes.opposite-left')},
        { value: "top", label: this.$t('finishes.top') },
        { value: "right", label: this.$t('finishes.right')},
        { value: "bottom", label: this.$t('finishes.bottom') },
        { value: "left", label: this.$t('finishes.left') }
      ]
    };
  },
  computed: {
    finish() {
      if (this.value.finishes.length > 0) {
        return this.value.finishes[0];
      }
      return null;
    },
    location() {
      return this.locations.find(
        location => location.value === this.value.type
      );
    }
  },
  components: {
    vSelect
  },
  methods: {
    change(val, type) {
      if(this.value.type == null){
       if(val[0] !== null){
        let copy = this.value;
        if (
          val[0] !== null &&
          val[0].hasOwnProperty("level") &&
          val[0].level === 8
        ) {
          copy["type"] = "surface";
          val.side = "surface";
        }
        copy[type] = val;
        this.$emit("input", copy);
       }
      }
      else{
        this.$emit("update",{val: val, old: this.value.finishes[0]});
      }
    }
  }
};
</script>
<style lang="scss">
@import "~vars";

.finish-extra {
  align-items: flex-start;

  .finish-select {
    flex: 0 0 50%;

      @media screen and (max-width: $tablet) {
      flex: 1;
      }
  }
  .v-select .vs__dropdown-toggle {
    border: none;
  }

  .vs__open-indicator {
    color: $black;
    fill: $black;
  }

  .vs__search::placeholder {
    opacity: 1;
  }

  .extra {
    height: 100%;
  }
}
</style>
