<template>
  <h2 class="is-size-6 price">
    <span v-if="price === null" class="button is-borderless is-loading" />
    <span v-if="price === false">n.t.b.</span>
    <span v-else>{{
      price
        | currency("€", 2, { thousandsSeparator: ".", decimalSeparator: "," })
    }}</span>
    <span class="frame-price" v-if="frames">Frames: {{ frames | currency("€", 2, { thousandsSeparator: ".", decimalSeparator: "," }) }}</span>
  </h2>
</template>
<script>
import currency from "../../utilities/filters/currency";

export default {
  props: {
    print: Object,
    frame: {
      type: Boolean,
      default: false
    },
    recalculate: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      price: null,
      frames: null
    };
  },
  filters: {
    currency
  },
  methods: {
    calculatePrice() {
      this.price = null;
       this.$http
        .post("/prices", { ...this.print })
        .then(({ data }) => {
          this.price = data.product;
          this.frames = data.frames || null

          this.$store.dispatch("addPriceToPrint", {
            printId: this.print.uuid,
            prices: data
          });
        })
        .catch(e => {
          this.price = false;
        });
    }
  },
  watch: {
    recalculate(newVal) {
      if (newVal === true) {
        this.calculatePrice();
        this.$emit('recalculated');
      }
    }
  },
  mounted() {
   this.calculatePrice();
  }
};
</script>
