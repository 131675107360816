<template>
  <form @submit.prevent="forgotPasswordLink" class="modal-card">
    <header class="modal-card-head">
      <h1 class="modal-card-title">
        <template v-if="!send">
          Enter E-mail
        </template>
        <template v-else>
          Check your mail
        </template>
      </h1>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <label for="email" class="label">Email</label>
        <div class="control">
          <input
            type="email"
            class="input"
            id="email"
            name="email"
            v-model="email"
            placeholder="Email"
            required
            autofocus
          />
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button name="login" class="button is-primary">
        Send password link
      </button>
      <router-link :to="{ name: 'login' }" class="td-underline ml-auto">Login</router-link>
    </footer>
  </form>
</template>
<script>
export default {
  name: "Forgot-Password",
  props: {
    value: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      send: false
    }
  },
  computed: {
    email: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    async forgotPasswordLink(e) {
      if (this.email) {
        let message = await this.$http.post('/auth/email', {email: this.email});
        this.send = true;
        this.$toasted.success(message.data.status);
      }
      else {
        this.$toasted.error("Email is required");
      }
    }
  }
}
</script>
