<template>
  <component :is="wrapperTag">
    <Node
      v-for="(branch, index) in sprout"
      :key="index"
      :level="0"
      v-model="sprout[index]"
      :ref="branch.id"
      :transition-group="transitionGroup"
      :on-item-click="onItemClick"
      :tag="tag"
      :node-class="nodeClass"
      :wrapperTag="wrapperTag"
    >
      <template slot-scope="_">
        <slot :vm="_.vm" :node="_.node" :toggle="_.toggle">
          <span @click="_.toggle">{{ _.node.name }}</span>
        </slot>
      </template>
    </Node>
  </component>
</template>
<script>
import uuidv1 from "uuid/v1";
import Node from "./Node";
export default {
  props: {
    seed: {
      type: Array,
      required: true
    },
    transitionGroup: {
      type: Object,
      default: () => ({
        name: "",
        tag: "ul",
        class: false
      })
    },
    wrapperTag: {
      type: String,
      default: "ul"
    },
    tag: {
      type: String,
      default: "li"
    },
    nodeClass: {
      type: [String, Object],
      default: "node"
    }
  },
  data() {
    return {
      sprout: false
    };
  },
  methods: {
    fertilize(seed) {
      function Model(item) {
        this.id = uuidv1();
        this.opened = item.opened || false;
        this.name = item.name || "";
        this.children = item.children || undefined;
        this.classes = item.classes || [];
        for (let fld in item) {
          this[fld] = item[fld];
        }
      }

      let node = Object.assign(new Model(seed));
      node.closeChildren = () => {
        node.opened = false;
        this.walk(node, node => {
          node.opened = false;
        });
      };
      (node.setClasses = value => {
        node.classes = value;
      }),
        (node.openChildren = () => {
          node.opened = true;
          this.walk(node, node => {
            node.opened = true;
          });
        });
      return node;
    },
    grow(seed) {
      if (seed && seed.length > 0) {
        return seed.map(node => {
          node = this.fertilize(node);
          if (node.children) {
            node.children = this.grow(node.children);
          }
          return node;
        });
      }
      return [];
    },
    walk(node, func) {
      if (func(node) !== false) {
        if (node.children && node.children.length > 0) {
          node.children.forEach(child => this.walk(child, func));
        }
      }
    },
    onItemClick(nodeVM, node) {
      this.lastClicked = nodeVM;
      this.$emit("item-click", nodeVM, node);
    }
  },
  created() {
    this.sprout = this.grow(this.seed);
  },
  components: {
    Node
  }
};
</script>
