<template>
  <component :is="tag" :class="getClass" @click.stop.prevent="handleItemClick">
    <slot :vm="this" :node="value" :toggle="toggle">
      <span @click="toggle">{{ value.name }}</span>
    </slot>
    <transition-group
      v-if="children.length > 0"
      v-show="value.opened"
      :name="transitionGroup.name"
      :tag="wrapperTag"
      :class="transitionGroup.class"
    >
      <node
        v-for="(node, index) in children"
        :key="node.id"
        :level="level + 1"
        :node-class="nodeClass"
        :transition-group="transitionGroup"
        v-model="children[index]"
        :on-item-click="onItemClick"
        :tag="tag"
        :wrapperTag="wrapperTag"
      >
        <template slot-scope="_">
          <slot :vm="_.vm" :node="_.node" :toggle="_.toggle">
            <span @click="_.toggle">{{ _.node.name }}</span>
          </slot>
        </template>
      </node>
    </transition-group>
  </component>
</template>
<script>
export default {
  name: "node",
  props: {
    value: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      required: true
    },
    onItemClick: {
      type: Function,
      default: () => false
    },
    transitionGroup: {
      type: Object,
      default: () => ({
        name: "",
        tag: "ul",
        class: false
      })
    },
    wrapperTag: {
      type: String,
      default: "ul"
    },
    tag: {
      type: String,
      default: "li"
    },
    nodeClass: {
      type: [String, Object],
      default: ""
    }
  },
  computed: {
    getClass() {
      return [
        {
          "is-opened": this.isOpened,
          "end-branch": this.isEndBranch
        },
        this.nodeClass,
        this.value.classes
      ];
    },
    children() {
      return (this.value.children && this.value.children.length) > 0
        ? this.value.children
        : [];
    },
    isLeaf() {
      return this.children.length === 0;
    },
    isEndBranch() {
      return this.children.filter(child => {
        return child.children === undefined;
      }).length > 0;
    },
    isOpened() {
      return this.value.opened;
    },
    itemSiblings() {
      if (this.itemParent.$children && this.itemParent.$children.length > 0) {
        return this.itemParent.$children;
      }
      return false;
    },
    itemParent() {
      if (this.$parent) {
        return this.$parent;
      }
      return false;
    }
  },
  methods: {
    toggle() {
      if (!this.isLeaf) {
        const duplicate = this.value;
        duplicate.opened = !duplicate.opened;
        this.$emit("input", duplicate);
      }
    },
    openChildren() {
      this.value.openChildren();
      this.$emit("input", this.value);
    },
    setClasses(value) {
      this.value.setClasses(value);
      this.$emit("input", this.value);
    },
    closeChildren() {
      this.value.closeChildren();
      this.$emit("input", this.value);
    },
    handleItemClick() {
      this.onItemClick(this, this.value);
    }
  }
};
</script>
