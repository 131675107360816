<template>
  <div class="copies-editor">
    <table class="table">
      <thead>
        <tr>
          <th>{{ $t('copies.originals') }}</th>
          <th>{{ $t('copies.quantity') }}</th>
          <th>{{ $t('copies.dimensions') }}</th>
          <th v-if="product.sides === 2">{{ $t('copies.side') }}</th>
          <th>{{ $tc('copies.remark', 2) }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <copy-row
          v-for="(copy, index) in value"
          :key="index"
          v-model="value[index]"
          :product="product"
          @addRow="addRow"
          :errors="getErrors(index)"
          :disableOriginalsWithId="disableOriginalsWithId"
        >
          <div class="copy-row__buttons">
            <button
              tabindex="-1"
              class="icon is-medium"
              @click.prevent="duplicateRow(index)"
            >
              <svg-icon icon="duplicate"/>
            </button>
            <button
              v-if="value.length > 1"
              tabindex="-1"
              class="delete"
              @click.prevent="removeRow(index)"
            />
          </div>
        </copy-row>
      </tbody>
    </table>
    <div>
      <button class="button is-text" @click.prevent="addRow">
        {{ $t('copies.addrow') }}
      </button>
    </div>
  </div>
</template>
<script>
import CopyRow from "./CopyRow.vue";
import { createCopy } from "../../utilities/helpers";
import clone from "lodash-es/cloneDeep";
export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    disableOriginalsWithId: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    addRow() {
      this.value.push(this.createCopy());
    },
    getErrors(index) {
      return this.errors.filter(error => {
        var matches = error.dataPath.match(/\[(.*?)\]/);
        if (matches) {
            var submatch = matches[1];
        }
        return submatch == index;
      })
    },
    createCopy() {
      return new createCopy(null, this.product);
    },
    removeRow(index) {
      this.value.splice(index, 1);
    },
    duplicateRow(index) {
      const row = clone(this.value[index]);
      delete row.id;
      this.value.push(row);
    }
  },
  components: {
    CopyRow
  }
};
</script>
<style lang="scss">
.frames-selector,
.copies-editor {
  .table td {
    border-width: 0;
  }

  .copy-row__buttons {
    display: flex;
  }
}
</style>
