<template>
  <div class="finish">
    <span class="icon is-medium">
      <svg-icon :icon="'finish-' + icon" :styling="{ transform: `rotate(${deg})` }" />
    </span>
    <v-select
      class="finish-select"
      :options="filtered"
      :value="copy"
      @input="change"
      label="name"
      multiple
      :tabindex="1"
    >
      <div
        class="control"
        slot="selected-option-container"
        slot-scope="{ option, deselect }"
      >
        <div class="tags has-addons">
          <span class="tag" :key="option.key">
            {{ option.name }}
          </span>
          <span
            aria-role="button"
            class="tag is-delete"
            @click="deselect(option)"
          />
        </div>
      </div>
    </v-select>
    <button class="delete" @click.prevent="$emit('delete')" />
  </div>
</template>
<script>
import vSelect from "vue-select";

export default {
  props: ["value", "finishes", "side", "maxLevel"],
  data() {
    return {
      copy: this.value,
      toExpand: {
        around: 4,
        "opposite-top": 2,
        "opposite-left": 2
      }
    };
  },
  computed: {
    filtered() {
      let finishes = JSON.parse(JSON.stringify(this.finishes)).map(finish => {
        finish.side = this.side;
        return finish;
      });
      return finishes.filter(finish => this.maxLevel.includes(finish.level));
    },
    deg() {
      switch (this.side) {
        case "opposite-left":
        case "right":
          return "90deg";
        case "bottom":
          return "180deg";
        case "left":
          return "270deg";
        default:
          return "0deg";
      }
    },
    icon() {
      switch (this.side) {
        case "left":
        case "right":
        case "bottom":
        case "top":
          return "side";
        case "opposite-top":
        case "opposite-left":
          return "opposite";
        default:
          return this.side;
      }
    }
  },
  methods: {
    change(finishes) {
      this.copy = finishes;
      this.$emit("input", this.copy);
    }
  },
  components: {
    vSelect
  }
};
</script>
<style lang="scss">
/* borders weghalen */
@import "~vars";

.finish {
  display: flex;
  align-items: center;
}

.finish-select {
  flex: 1 0 auto;
  max-width: 380px;
}

.level-2:first-child {
  border-top: 1px solid red;
}
</style>
