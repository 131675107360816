<template>
  <div class="cart-view">
    <div class="cart-items">
      <cart-item
        v-for="print in cart"
        :print="print"
        :key="print.uuid"
        :should-collapse="shouldCollapse"
      />
    </div>
    <div class="total-prints">
      <div class="columns">
        <div class="column is-4">
          <div class="f-bold">{{ $t('checkout.total_label') }}</div>
        </div>
        <div class="column is-3">
          <strong>{{ $tc('checkout.total_counter', totalCopies) }}</strong>
        </div>
        <div class="column">
          <div v-if="totalPrice === false || totalPrice === 0"></div>
          <div v-else class="f-bold">
            {{ totalPrice | currency("€", 2, {thousandsSeparator: ".", decimalSeparator: ","}) }}
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div>{{ $t('checkout.transport_indication') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import currency from "../../utilities/filters/currency";
import CartItem from "./CartItem";

export default {
  name: "CartView",
  data() {
    return {
      currentHeight: null
    }
  },
  computed: {
    ...mapGetters(["cart", "totalPrice", "totalCopies", "order"]),
    shouldCollapse() {
      return this.currentHeight > window.innerHeight - 225;
    },
  },
  mounted() {
    this.currentHeight = this.$el.offsetHeight;
  },
  filters: {
    currency
  },
  components: {
    CartItem,
  }
}
</script>
