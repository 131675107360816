<template>
  <div class="finish-preview">
    <finish-side :side="top" :errors="errors" name="top" class="finish-side" />
    <finish-side
      :side="left"
      :errors="errors"
      name="left"
      class="finish-side"
    />
    <img class="preview-image" src="../../assets/images/finish-preview.jpg" />
    <finish-side
      :side="right"
      :errors="errors"
      name="right"
      class="finish-side"
    />
    <finish-side
      :side="bottom"
      :errors="errors"
      name="bottom"
      class="finish-side"
    />
  </div>
</template>
<script>
import FinishSide from "./FinishSide";
import { extractFinishes } from "../../utilities/helpers";

export default {
  props: {
    finishes: {
      type: Array,
      required: true,
    },
    errors: {
      type: Array,
      default: () => {return []}
    }
  },
  computed: {
    top() {
      return this.extracted.filter(finish => finish.side === "top");
    },
    right() {
      return this.extracted.filter(finish => finish.side === "right");
    },
    bottom() {
      return this.extracted.filter(finish => finish.side === "bottom");
    },
    left() {
      return this.extracted.filter(finish => finish.side === "left");
    },
    extracted() {
      let finishes = [];
      this.finishes.forEach(finish => {
        finishes.push(...extractFinishes(finish, finish.side));
      });
      return finishes;
    }
  },
  components: {
    FinishSide
  }
};
</script>
<style lang="scss">
@import "~vars";
@import "~bulma/sass/utilities/mixins";

.finish-preview {
  display: grid;
  grid-template-columns: 1fr minmax(100px, 1fr) 1fr;
  grid-template-rows: 1fr minmax(100px, 4fr) 1fr;
  grid-gap: 1rem;
  align-items: center;
}

.preview-image {
  grid-row: 2;
  grid-column: 2;
  border: 2px #000 solid;
}

.finish-side.top {
  grid-row: 1;
  grid-column: 1 / 4;
  text-align: center;
}

.finish-side.right,.finish-side.left {
  min-width: 0;

  span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.finish-side.right {
  grid-row: 2;
  grid-column: 3;
}

.finish-side.bottom {
  grid-column: 1 / 4;
  grid-row: 3;
  text-align: center;
}

.finish-side.left{
  grid-row: 2;
  grid-column: 1;
  justify-content: flex-end;
  text-align: right;
}
</style>
