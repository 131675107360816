<template>
  <div class="column is-12">
    <div class="columns is-centered">
      <div class="column is-5 pt-10">
        <transition name="fade" mode="out-in">
          <span class="title is-1" :key="text" v-html="text" />
        </transition>
        <loading-screen
          class="boot-loading"
          v-model="loading"
          position="right-bottom"
          @close="nextPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LoadingScreen from "../components/LoadingScreen";

export default {
  data() {
    return {
      texts: [],
      text: "",
      interval: 0,
      loading: true
    };
  },
  methods: {
    randomText() {
      this.text = this.texts[Math.floor(Math.random() * this.texts.length)];
      return setInterval(() => {
        this.text = this.texts[Math.floor(Math.random() * this.texts.length)];
      }, 2000);
    },
    nextPage() {
      if (this.$route.query.url) {
        this.$router.push(this.$route.query.url);
      } else {
        this.$router.push({ name: "home" });
      }
    }
  },
  computed: {
    ...mapState(["user"])
  },
  created() {
    this.texts.push(
      ...[
        'Setting things <span class="c-cyan">up...</span>',
        `Welcome <span class="c-magenta">${this.user.name}</span>`,
        "Just a few seconds before printing"
      ]
    );
    this.interval = this.randomText();
  },
  async mounted() {
    try {
      await this.$store.dispatch("boot");
      // Close boot screen
      clearInterval(this.interval);
      this.loading = false;
      this.text = "Ready<span class='c-cyan'>!</span>";
    } catch (error) {
      console.log(error);
    }
  },
  components: {
    LoadingScreen
  }
};
</script>

<style>
.boot-loading.loading {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
}
</style>
