<template>
  <div class="modal-card-body">
    <ul class="columns is-multiline is-centered">
      <li
        class="column is-12 partner"
        v-for="partner in partners"
        :key="partner.id"
      >
        <span class="button is-medium" @click.prevent="submit(partner.id)">
          {{ partner.name }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    partners: {
      type: Array,
      required: true
    }
  },

  methods: {
    submit(partner) {
      this.$emit("submit", partner);
    }
  }
};
</script>

<style lang="scss">
.partners {
}

.partner {
  text-align: center;

  .button {
    width: 100%;
  }
}
</style>
