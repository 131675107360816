import { ResponsiveMixin } from "vue-responsive-components"

export default {
  mixins: [ResponsiveMixin],
  breakpoints: {
    'is-small': el => el.width <= 768,
    'is-medium': el => el.width > 768,
    'is-medium-only': el => el.width > 768 && el.width <= 1024,
    'is-large': el => el.width > 1024,
    'is-large-only': el => el.width > 1024 && el.width <= 1216,
    'is-wide': el => el.width > 1216,
    'is-wide-only': el => el.width > 1216 && el.width <= 1408,
    'is-fullhd': el => el.width > 1408,
    'is-burger': el => el.width <= 1024
  },
}