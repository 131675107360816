<template>
  <div id="app">
    <navbar />
    <main class="main section">
      <transition :name="$route.meta.transition" mode="out-in">
        <keep-alive :include="/Selector|Overview/" :max="1">
          <router-view />
        </keep-alive>
        <!-- page view -->
      </transition>
    </main>
    <loading-screen
      class="loading-home"
      v-if="showLoading"
      v-model="loading"
      @close="showLoading = false"
    />
    <help-button v-if="booted"></help-button>
    <qr-button v-if="false"></qr-button>
    <!-- loading screen -->
  </div>
</template>

<script>
import LoadingScreen from "./LoadingScreen";
import Navbar from "./Navbar";
import HelpButton from "./MenuButtons/HelpButton.vue";
import QrButton from "./MenuButtons/QRButton.vue";
import { SET_LOADING } from "../store/mutation-types";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      showLoading: false,
    };
  },
  methods: {
    prevent() {
      if (this.$store.state.active) {
        var confirmationMessage = "o/";
        event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
        return confirmationMessage;
      }
    },
  },
  computed: {
    ...mapGetters({
      booted: "startup",
      auth: "auth",
    }),
    loading: {
      get() {
        return this.$store.state.loading;
      },
      set(val) {
        this.$store.commit(SET_LOADING, val);
      },
    },
  },
  watch: {
    loading(val) {
      if (val === true && this.showLoading === false) {
        this.showLoading = true;
      }
    },
  },
  created() {
    this.$store.watch(
      (state, getters) => getters.auth,
      (value) => {
        if (value === false) {
          this.$router.push({ name: "login" });
        }
      }
    );
  },
  components: {
    LoadingScreen,
    Navbar,
    HelpButton,
    QrButton,
  },
};
</script>
<style lang="scss">
@import "~vars";
@import "~bulma/sass/utilities/mixins";

@include mobile() {
  main.main {
    padding: 0;
  }
}

#app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

main.main {
  flex: 1;
  padding-top: 0;
}

.loading-home {
  background-color: rgba($white, 0.8);
  position: fixed;
  height: 50vh;
  width: 50vh;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
