<template>
  <div class="vuedp-date-picker">
    <label class="label" v-if="label">
      {{ label }}
      <input
        :type="inline ? 'hidden' : 'text'"
        name="vuedp-date-picker-input"
        :value="formattedValue"
        @click.stop.prevent="showCalendar"
        :class="inputClass"
        readonly
      />
    </label>
    <div class="vuedp-date-picker__calendar box" ref="calendar" v-show="!collapsed">
      <!--I want to reuse the header and not repeat it-->
      <header class="date-picker-nav">
        <button @click.prevent="previousMonth" class="date-picker-nav__prev">
          <span class="sr-only">previous month</span>
        </button>
        <h2 class="is-size-5">
          {{ displayMonth }} {{ displayYear }}
        </h2>
        <button @click.prevent="nextMonth" class="date-picker-nav__next">
          <span class="sr-only">next month</span>
        </button>
      </header>
      <div class="weekdays">
        <span class="cell weekday" v-for="day in daysOfWeek" :key="day">
          {{ day }}
        </span>
      </div>
      <div class="days">
        <day
          @click="chooseDate"
          class="cell"
          v-for="day in days"
          :key="day.date.getTime()"
          :class="{
            'prev-month': day.type === 'prev',
            'next-month': day.type === 'next'
          }"
          :day="day"
          :stateList="stateList()"
        />
      </div>
      <div class="time-scale">
        <span class="is-size-6 time-label">Afleveren voor:</span>
        <div class="select">
          <select v-model="time">
            <option v-for="hour in openingHours" :key="'time--' + hour">
              {{ hour }}
            </option>
          </select>
        </div>
        <div class="select is-arrowless">
          <select disabled>
            <option selected>00</option>
          </select>
        </div>
      </div>
      <ul class="help colour-legenda">
        <li class="combi">{{ $t('date_picker.combination') }}</li>
        <li class="speed">{{ $t('date_picker.speed') }}</li>
        <li class="contact">{{ $t('date_picker.contact') }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
/*
        The property highlight and disabled should be wrappers around a state object
        So perhaps this state object should be a computed property, but also a property
    */

import {
  eachDayOfInterval,
  differenceInDays,
  getDaysInMonth,
  startOfISOWeek,
  endOfISOWeek,
  startOfMonth,
  endOfMonth,
  getISODay,
  getDate,
  sub,
  add,
  set
} from "date-fns";
import Day from "./Day.vue";

export default {
  name: "mwt-DatePicker",
  props: {
    inline: Boolean,
    locale: String,
    showTime: Boolean,
    highlight: Date,
    label: String,
    disabled: Object,
    states: Object,
    inputClass: String,
    value: Date,
    focus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedDate: this.checkDisabled(this.value) || null,
      currentDate: this.value || new Date(),
      collapsed: !this.focus || true,
      time: 17,
      openingHours: [9, 12, 15, 17]
    };
  },
  computed: {
    formattedValue() {
      if (this.value) {
        return new Intl.DateTimeFormat(this.$store.state.context, {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit"
        }).format(this.value);
      }
      return "";
    },
    displayMonth() {
      return this.currentDate.toLocaleDateString(this.$store.state.context, {
        month: "long"
      });
    },
    displayYear() {
      return this.currentDate.toLocaleDateString(this.$store.state.context, {
        year: "numeric"
      });
    },
    daysOfWeek() {
      const weekDays = eachDayOfInterval({
        start: startOfISOWeek(this.currentDate),
        end: endOfISOWeek(this.currentDate)
      });

      return weekDays.map(day => day.toLocaleDateString(this.$store.state.context, { weekday: "short" }));
    },
    daysPrevMonth() {
      const monthStart = startOfMonth(this.currentDate);
      const range = differenceInDays(monthStart, startOfISOWeek(monthStart));

      return this.getDays(range, startOfISOWeek(monthStart),'prev');
    },
    days() {
      const range = getDaysInMonth(this.currentDate);

      return [
        ...this.daysPrevMonth,
        ...this.getDays(range, startOfMonth(this.currentDate), "current"),
        ...this.daysNextMonth
      ];
    },
    daysNextMonth() {
      const endOfMonthDate = endOfMonth(this.currentDate);
      const endOfWeekMonth = endOfISOWeek(endOfMonthDate);
      const range = differenceInDays(endOfWeekMonth, endOfMonthDate);

      return this.getDays(range, startOfMonth(endOfWeekMonth), "next");
    }
  },

  methods: {
    getDays(range, date, type) {
      const days = [...Array(range).keys()];

      // Map the days with a date object
      return days.map(day => {
        const newDate = add(date, { days: day });

        return {
          date: newDate,
          day: getDate(newDate),
          type
        };
      });
    },
    checkDisabled(dateVal) {
      if (this.states.disabled.days.includes(getISODay(dateVal))) {
        dateVal = startOfISOWeek(add(dateVal, { weeks: 1 }));
      }
      this.$emit("input", dateVal);

      return dateVal;
    },
    showCalendar() {
      this.collapsed = !this.collapsed;
      const element = this.$refs.calendar;
      const vm = this;

      const removeEvent = () => {
        document.removeEventListener("click", addEvent);
      };
      const addEvent = e => {
        e.stopPropagation();
        if (!element.contains(e.target)) {
          this.collapsed = true;
        }
        removeEvent();
      };
      document.addEventListener("click", addEvent.bind(vm));
    },
    previousMonth() {
      this.currentDate = sub(this.currentDate, { months: 1 });
    },
    showMonthCalendar() {},
    stateList() {
      return {
        ...this.states,
        selectedDate: this.selectedDate
      };
    },
    nextMonth() {
      this.currentDate = add(this.currentDate, { months: 1 });
    },
    chooseDate(e) {
      if (e.state['disabled']) {
        return;
      }

      if (e.state["last-month"]) {
        const selectedDate = e.date;
        this.selectedDate = selectedDate;
        this.currentDate = selectedDate;
      }

      if (e.state["next-month"]) {
        const selectedDate = e.date;
        this.selectedDate = selectedDate;
        this.currentDate = selectedDate;
      }

      this.selectedDate = set(e.date, { hours: this.time, minutes: 0, seconds: 0, milliseconds: 0 });
      this.collapsed = true;

      this.$emit("input", this.selectedDate);
    }
  },

  watch: {
    value(val) {
      this.selectedDate = this.checkDisabled(new Date(val));
    },
    time(val) {
      this.selectedDate = set(this.selectedDate, { hours: this.time });
      this.$emit("input", this.selectedDate);
    }
  },

  components: {
    Day
  }
};
</script>

<style lang="scss">
@import "~vars";

.vuedp-date-picker__calendar,
button,
input {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}

// Bootstrap sr-only
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.vuedp-date-picker__calendar {
  position: absolute;
  z-index: 30;
  background-color: #fff;
  width: 300px;
  border: 1px solid #ccc;

  /** General **/
  .cell {
    width: percentage(1/7);
    text-align: center;
    line-height: 40px;
    border: 1px solid transparent;
    box-sizing: border-box;
  }

  /** Header controls **/
  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    line-height: 40px;

    button {
      display: inline-block;
      flex: 1 0 auto;
      background: #fff;
      padding: 00;
      border: none;
      outline: none;

      &:hover {
        background: #eee;
      }
    }

    .date-picker-nav__prev,
    .date-picker-nav__next {
      flex: 0 1 40px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        border: 6px solid transparent;
      }
    }

    .date-picker-nav__prev::after {
      border-right: 10px solid #000;
      margin-left: -5px;
    }
    .date-picker-nav__next::after {
      border-left: 10px solid #000;
      margin-left: 5px;
    }
  }

  /** Weekdays **/
  .weekdays {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;

    .weekday {
      font-size: 0.75em;
    }
  }

  .days {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .cell {
      cursor: pointer;

      &:hover {
        border-color: #01a1ef;
      }
      &.prev-month,
      &.next-month {
        opacity: 0.55;
      }
      &.highlighted {
        background: lighten(#01a1ef, 20);
        color: #fff;
      }
      &.selected {
        color: #000;
        border: 2px solid #58c8fe;
      }
      &.disabled {
        background-color: transparent !important;
        color: #dadada;
        cursor: default;

        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  .time-scale {
    display: flex;
    align-items: center;
    padding: 1.25rem 0;

    &.box {
      margin-bottom: 0;
    }

    .time-label {
      margin-right: auto;
    }
  }

  .colour-legenda li {
    padding-left: 0.5rem;
    height: 1.5rem;
    line-height: 1.5rem;

    &.combi::before,
    &.contact::before,
    &.speed::before {
      content: '';
      height: 1.5rem;
      width: 1.5rem;
      display: block;
      margin-right: 0.5rem;
      float: left;
      background-color: $grey;
    }

    &.combi::before {
      background-color: $cyan;
    }

    &.contact::before {
      background-color: $danger;
    }

    &.speed::before {
      background-color: $yellow;
    }
  }
}
</style>
