export const personal = { name: 'personal', icon: 'user' };
export const catalog = { name: 'catalog', icon: 'selector' };
export const frames = { name: 'frames', icon: 'plusframe' };
export const vsigns = { name: 'vsigns', icon: 'vsign' };
export const fence_banners = { name: 'fence_banners', icon: 'fencebanner' };
export const flags = { name: 'flags', icon: 'staande-vlag-zonder-tunnel' };
export const adhesive = { name: 'adhesive', icon: 'adhesive-duurzaam' };

export const topChapters = { personal, catalog }
export const lowerChapters = { frames, vsigns, fence_banners, flags, adhesive }

export default [
  personal,
  catalog,
  frames,
  vsigns,
  fence_banners,
  flags,
  adhesive
]