import Vue from "vue";

export function getToken() {
  const token = localStorage.getItem("token");
  if (token === null) {
    return "";
  }

  return token;
}

/**
 *
 * @param token
 * @returns {*}
 */
export function setToken(token) {
  localStorage.setItem("token", token);
  return token;
}

export function uuid(a) {
  return a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);
}

export function fillHeaderAuth(token = null) {
  Vue.http.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    Authorization: "Bearer " + (token !== null ? setToken(token) : getToken())
  };

  if (token === "") {
    delete Vue.http.defaults.headers.common.Authorization;
  }

  return getToken();
}

export class FrameCopy {
  constructor(sizes = undefined) {
    this.id = undefined;
    this.support = false;
    this.base = false;
    this.wallmount = false;
    this.sizes = {
      pieces: undefined,
      width: undefined,
      height: undefined
    };

    if (sizes) {
      this.sizes.width = sizes.width || undefined;
      this.sizes.height = sizes.height || undefined;
    }
  }
}

export const createOrderDetails = () => ({
  description: "",
  reference: "",
  files_date: null,
  delivery_date: null,
  address: false,
  approve_pdf: false,
  archive_files: false,
  pickup: false,
  pack: false
});

export const createPrint = product => ({
  product_id: product,
  copies: [],
  finishes: [],
  complete: false,
  uuid: uuid(),
  prices: null
});

export function createCopy(obj, product = undefined) {
  const fixedWidth =
    (product && product.fixed_width) > 0 ? product.fixed_width : null;
  const fixedHeight =
    (product && product.fixed_height) > 0 ? product.fixed_height : null;
  this.originals = null;
  this.quantity = null;
  this.width = fixedWidth;
  this.height = fixedHeight;
  this.remark = "";
  this.sides = product.sides;
  this.abaa = 1;

  for (let fld in obj) {
    this[fld] = obj[fld];
  }
}

export function extractFinishes(finish, side) {
  let expanded = [];
  const sides = ["top", "right", "bottom", "left"];
  const opposites = {
    top: "bottom",
    left: "right"
  };
  let expand = 1;
  switch (side) {
    case "around":
      expand = 4;
      break;
    case "opposite-top":
    case "opposite-left":
      expand = 2;
      break;
  }

  function getSideName(side, key) {
    if (side === "around") {
      return sides[key];
    }
    if (side.indexOf("-") > -1) {
      const name = side.split("-")[1];
      return key === 0 ? name : opposites[name];
    }
    return side;
  }

  expanded = Array(expand)
    .fill(finish)
    .map((finish, index) => {
      return new createFinish(finish, getSideName(side, index));
    });

  return expanded;
}

export function sideCheck(sides) {
  if (!Array.isArray(sides) || !sides.length) return [];
  const sidesToCheck = ["top", "right", "bottom", "left"];
  return sidesToCheck.filter(x => !sides.includes(x));
}

export function createFinish(obj, side) {
  for (let fld in obj) {
    this[fld] = obj[fld];
  }

  this.side = side;
}
