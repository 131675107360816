<template>
  <div
    @mouseleave="startTimer" @mouseover="removeTimer"
    class="qr-button"
  >
    <div 

      class="dropdown is-up is-right" 
      :class="{ 'is-active': open }"
      @click="open = !open"
    >
      <div class="dropdown-trigger">
        <button
          class="icon is-large"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
          </svg>

        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <a href="https://mijnverpakking.online/" target="_blank" class="dropdown-item">
            scan uw pakket
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      open: false,
      timer: undefined,
    };
  },
  methods: {
    startTimer() {
      this.timer = setTimeout(() => {
        this.open = false;
        this.qr = false;
      }, 500);
    },
    removeTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = undefined;
    },
  },
};
</script>
<style lang="scss">
  // mobile
  @media screen and (max-width: 768px) {
  .qr-button{
    bottom: 7rem !important;
  }
  }

  .qr-button{
    position: fixed;
    bottom: 5rem;
    right: 0.5rem;
  
  }

  .dropdown-menu {
    margin-right: 2.5rem;
  }

</style>
