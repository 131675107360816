<template>
  <tree
    class="entry-category category-list"
    nodeClass="category"
    wrapperTag="div"
    tag="span"
    :transitionGroup="transitionGroup"
    :seed="data"
  >
    <template slot-scope="_">
      <product v-if="_.vm.isLeaf && _.node.default_code" :product="_.node" />
      <div
        v-else-if="_.node.children && _.node.children.length > 0"
        class="button is-text is-category"
        @click="handleClick(_.vm)"
        :class="{ 'is-selected': _.vm.isOpened }"
      >
        <span>{{ _.node.name }}</span>
        <span class="icon is-small">
          <svg-icon icon="chevron" />
        </span>
      </div>
      <span v-else></span>
    </template>
  </tree>
</template>

<script>
import Product from "./Product";
import Tree from "../ScopedTree/Tree";
export default {
  name: "category",
  props: ["data"],
  data() {
    return {
      transitionGroup: {
        name: "switch",
        class: "category-list"
      }
    };
  },
  methods: {
    handleClick(vm) {
      if (vm.isOpened) {
        vm.closeChildren();
        vm.itemSiblings.forEach(node => {
          node.setClasses([]);
        });
      } else {
        vm.itemSiblings.forEach(node => {
          node.closeChildren();
          node.setClasses(["not-opened", "slide"]);
        });
        vm.setClasses(["slide"]);

        vm.toggle();
      }
    }
  },
  components: {
    Tree,
    Product
  }
};
</script>
<style lang="scss">
  .button.is-category {
    .icon.is-small {
      height: 0.75rem;
      width: 0.75rem;
    }
  }
</style>