<template>
  <nav class="navbar is-spaced">
    <div class="navbar-brand">
      <router-link :to="{ name: 'home' }" class="navbar-item" :class="{'is-hover': hover}">
        <figure class="logo" @mouseover="hover = true" @mouseout="hover = false">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="53.9px"
            height="53.9px"
            viewBox="0 0 53.9 53.9"
            style="enable-background:new 0 0 53.9 53.9;"
            xml:space="preserve"
            :style="logoColor"
          >
            <defs />
            <g>
              <polygon
                class="st0"
                points="45.2,53.9 0,53.9 0,8.7 22.6,8.7 22.6,13.9 5.2,13.9 5.2,48.7 40,48.7 40,31.3 45.2,31.3"
              />
            </g>
            <g
              class="plus"
              :style="{
                transform: $route.name === '404' ? 'rotate(135deg)' : ''
              }"
            >
              <rect x="40" class="st0" width="5.2" height="22.6" />
              <rect x="31.3" y="8.7" class="st0" width="22.6" height="5.2" />
            </g>
          </svg>
        </figure>
        <span class="subtitle logo-home">Home</span>
      </router-link>
      <a role="button" class="navbar-burger" data-target="navMenu" aria-label="menu" aria-expanded="true">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <span class="printpage-head">{{$t($route.meta.title, $route.params)}}</span>
    <div class="navbar-menu" id="navMenu" v-if="auth">
      <div class="navbar-start">
        <div class="navbar-item" v-if="$route.meta.title">
          <transition name="fade" :duration="120" mode="out-in">
            <span class="title extra" :key="$route.meta.title">{{ $t($route.meta.title, $route.params) }}</span>
          </transition>
        </div>
      </div>
      <div class="navbar-end">
        <transition name="fade">
          <router-link :to="{ name: 'home' }" class="navbar-item" v-if="ordering">
            {{ $t('navbar.back') }}
          </router-link>
        </transition>
        <router-link class="navbar-item" :to="{ name: 'checkout' }">
          <div class="nav-icon has-counter" v-if="!el.is['is-burger']">
            <svg-icon icon="cart" />
            <div class="counter">
              <span class="counter-count">
                <strong class="badge">{{ cart.length }}</strong>
              </span>
            </div>
          </div>
          <span class="user_nav_icon_holder" v-else>
            Winkelwagen
          </span>
          
        </router-link>
        <div class="navbar-item has-dropdown is-hoverable">
          <span class="navbar-link">
            <span class="user_nav_icon_holder">
              <svg-icon icon="user" class="nav-icon" v-if="!el.is['is-burger'] "/>
              <span class="account-link" v-else>Account</span>
              <strong v-if="!$store.state.hasSeenSpecs" class="badge is-primary"></strong>
            </span>
          </span>
          <div class="navbar-dropdown is-right is-hoverable">
            <router-link v-if="$store.state.user" class="navbar-item" :to="{ name: 'settings' }">
              {{ $t('navbar.settings') }}
            </router-link>
            <a href="#" @click="downloadPdf" class="navbar-item">
              {{ $t('ui.specs')}}
              <strong v-if="!$store.state.hasSeenSpecs" class="new-label">
                {{ $t('ui.new') }}
              </strong>
            </a>
            <a class="navbar-item" @click.prevent="logout">
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <!-- Navbar -->
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { HAS_SEEN_SPECS } from '../store/mutation-types'
import responsiveMixin from "../utilities/responsiveMixin";

export default {
  data() {
    return {
      hover: false
    }
  },
  mounted () {
    // close navbar on click outside
    document.addEventListener('click', (e) => {
      if (e.target.classList.contains('navbar-burger') || e.target.classList.contains('account-link') || e.target.classList.contains('navbar-link') || e.target.classList.contains('user_nav_icon_holder')) {
        return;
      }
      const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
      $navbarBurgers.forEach( el => {
        el.classList.remove('is-active');
        document.getElementById(el.dataset.target).classList.remove('is-active');
      });
    });
    // add click event to navbar-burger
    document.addEventListener('DOMContentLoaded', () => {
      // Get all "navbar-burger" elements
      const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

      // Add a click event on each of them
      $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {

          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
          
                    

        });
      });
  });
  },
  computed: {
    logoColor() {
      return {
        fill: this.$route.name === "login" || !this.booted ? "#fff" : "#000"
      };
    },
    ordering() {
      switch (this.$route.name) {
        case "home":
        case "loading":
        case "login":
          return false;
        default:
          return true;
      }
    },
    ...mapGetters({
      booted: "startup",
      auth: "auth",
      cart: "cart"
    })
  },
  mixins: [responsiveMixin],
  methods: {
    ...mapActions(["logout"]),
    downloadPdf() {
      this.$http({
        url: `/specs`,
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.$t('ui.specs')}.pdf`);
        document.body.appendChild(link);
        link.click();

        this.$store.commit(HAS_SEEN_SPECS, true);
      })
    },
  }
};
</script>
<style lang="scss">
@import "~vars";

.navbar {
  flex-shrink: 0;
  padding-bottom: 1rem;
}

.navbar-item {
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: left;
}

.logo {
  pointer-events: bounding-box;
  z-index: 1;
}

.logo .plus {
  transition: transform 0.2s ease-in;
  transform-origin: 80% 20%;

  .is-hover &{
    transform: rotate(90deg);
  }
}

.logo-home {
  position: absolute;
  opacity: 0;
  transform: translateY(0);
  transition: transform 0.2s ease-in;
  transition-property: transform, opacity;
}

.is-hover .logo-home {
  opacity: 1;
  transform: translateY(40px);
}

.nav-icon {
  cursor: pointer;
  width: 32px;
  height: 32px;

  &.has-counter {
    position: relative;

    .counter {
      position: absolute;
      right: 0px;
      top: 0px;
      transform: translate(50%, -38%);

      .badge {
        position: relative;
        min-height: initial;
      }
    }
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.user_nav_icon_holder {
  display: inherit;
  position: relative;

  .badge {
    position: absolute;
    right: -0.5rem;
    top: -0.30rem;
  }
}

.new-label {
  margin-left: 1rem;
  color: $cyan;
}

.badge {
  width: 1rem;
  display: inline-block;
  text-align: center;
  background-color: #000;
  color: #fff;
  font-size: 0.6875rem;
  border-radius: 50%;
  line-height: 1rem;
  min-height: 1rem;
  margin-left: 5px;

  &.is-primary {
    background-color: $cyan
  }
}
@media screen{
.printpage-head{
    visibility: hidden;
    position: absolute;
    border: solid;
}
}

//mobile and tablet title hidden
@media screen and (max-width: 1023px){
.title.extra{
  visibility: hidden;
  position: absolute;
  border: solid;
}
}
</style>
