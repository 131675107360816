<template>
  <div>
    <h2 class="is-size-7">{{ $t('printer.details') }}</h2>
    <table class="table copies__view" :class="{'is-narrow': narrow}">
      <thead>
        <tr>
          <th class="is-narrow">{{ $t('copies_short.originals') }}</th>
          <th class="is-narrow">{{ $t('copies_short.quantity') }}</th>
          <th class="is-narrow">{{ $t('copies_short.dimensions') }}</th>
          <th class="is-narrow">{{ $t('copies_short.side_printed') }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="copy in copies" :key="copy.height + copy.width + copy.originals + copy.quantity">
          <td>{{ copy.originals }}</td>
          <td>{{ copy.quantity }}</td>
          <td>{{ copy.width }}x{{ copy.height }}</td>
          <td>{{ copy.sides }}</td>
          <td>{{ copy.remark }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    copies: {
      type: Array,
      required: true,
    },
    narrow: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang=scss>
  .copies__view {
    font-size: 0.9rem;
    display: inline-table;
  }
</style>