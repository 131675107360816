<template>
  <div
    class="product"
    @mouseover.prevent="startTimer(product)"
    @mouseout="clearTimer"
  >
    <button @click.stop="createPrint(product.id)" class="button is-text">
      <span class="product-name">{{ product.name }}</span>
    </button>
  </div>
</template>
<script>
import Vevent from "@/utilities/Vevent";
export default {
  props: {
    product: Object
  },
  data() {
    return {
      timer: false
    };
  },
  methods: {
    createPrint(productId) {
      this.$store.dispatch("createPrint", productId);
      this.$router.push({ name: "copies" });
    },
    startTimer(e) {
      this.timer = setTimeout(() => {
        this.showInfo(e);
      }, 600);
    },
    clearTimer() {
      clearTimeout(this.timer);
    },
    showInfo(e) {
      Vevent.fire("product-info", e);
    }
  }
};
</script>
<style lang="scss">
@import "~vars";
@import "~bulma/sass/utilities/mixins";

.product {
  max-width: fit-content;
  button {
    width: 100%;
  }
}

@include desktop() {
  .product-name {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-align: left;
    display: inline-block;
  }
}
</style>
