import {
  FILL_CATALOG,
  FILL_PERSONAL,
  FILL_FLAGS,
  FILL_FRAMES,
  FILL_VSIGNS,
  FILL_FENCEBANNERS,
  ADD_PRODUCT_TO_PERSONAL,
  DELETE_PRODUCT_TO_PERSONAL,
  FILL_ADHESIVE,
  FILL_CURTAINS,
} from "../../mutation-types";

const set = type => (state, value) => {
  state[type] = value;
};

const push = type => (state, value) => {
  if (state[type].push) {
    state[type].push(value);
    return;
  }
  state[type] + value;
  return;
};

export default {
  state: {
    catalog: [],
    personal: [],
    flags: [],
    frames: [],
    vsigns: [],
    fence_banners: [],
    adhesive: [],
  },
  getters: {
    getProductCategory: state => categoryId => {
      return state.catalog.find(category => category.id === categoryId);
    },
    getFrameName: state => frameId => {
      const frame = state.frames.find(frame => frame.id === frameId);

      return frame.name;
    }
  },
  mutations: {
    [FILL_CATALOG]: set("catalog"),
    [FILL_PERSONAL]: set("personal"),
    [FILL_FLAGS]: set("flags"),
    [FILL_FRAMES]: set("frames"),
    [FILL_VSIGNS]: set("vsigns"),
    [FILL_FENCEBANNERS]: set("fence_banners"),
    [FILL_ADHESIVE]: set("adhesive"),
    [FILL_CURTAINS]: set('curtains'),
    [ADD_PRODUCT_TO_PERSONAL]: push("personal"),
    [DELETE_PRODUCT_TO_PERSONAL](state, productId) {
      const updateIndex = state.personal.findIndex(
        current => current.id === productId
      );
      state.personal.splice(updateIndex, 1);
    }
  }
};
