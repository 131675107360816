import Ajv from "ajv";
import en from "ajv-i18n/localize/en";
import nl from "ajv-i18n/localize/nl";
import store from "../store";

const localize = {
  nl,
  en
};



export function transformError(errors) {
  // Is axios error
  if (errors.response) {
    if (Array.isArray(errors.response.data)) {
      return errors.response.data;
    }

    return {};
  }

  // AJV
  return errors.reduce((acc, error) => {
    const key = error.params.missingProperty || error.dataPath.substring(1);
    if (!acc.hasOwnProperty(key)) {
      acc[key] = [error.message];
    } else {
      acc[key].push(error.message);
    }
    return acc;
  }, {});
}

export function validate(schema, data, context = store.state.context) {
  let ajv = new Ajv({ allErrors: true, verbose: true, format: "full" });
  let valid = ajv.validate(schema, data);

  if (!valid) {
    localize[context](ajv.errors);

    ajv.errors = ajv.errors.filter(error => {
      return error.keyword !== "if";
    });

    throw ajv.errors;
  }

  return true;
}
