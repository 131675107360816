<template>
  <div>
    <div class="finishes">
      <div class="selection">
        <finish-editor
          v-model="selected"
          :finishes="finishes"
          :product="product"
          @error="errors = $event"
        />
        <hr />
      </div>
      <div class="preview" v-if="normalFinishes.length > 0 && ! el.is['is-small']">
        <finish-preview
          :errors="errors"
          :finishes="
            selected.filter(finish =>
              finish.level !== 9 && finish.level !== 8 ? finish : false
            )
          "
        />
      </div>
    </div>
    <div class="disclaimer" v-if="normalFinishes.length == 0">  
      <h1>AANTALLEN</h1>
      <p> Het juiste aantal extra producten wordt automatisch berekend op basis van de door u opgegeven framemaat. Bijvoorbeeld wanneer u baseplates besteld zijn dit er altijd minimaal twee per frame, hierna zal er elke 2000mm een extra bijkomen. Als u afwijkende aantallen extra producten wenst te ontvangen, neem dan contact met ons op.</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import responsiveMixin from "../../utilities/responsiveMixin";
import FinishEditor from "../../components/Finishes/FinishEditor";
import FinishPreview from "../../components/Finishes/FinishPreview.vue";

export default {
  data() {
    return {
      selected: [],
      extra: {
        finishes: [],
        type: null
      },
      errors: [],
      sides: ["top", "right", "bottom", "left"],
      opposites: {
        top: "bottom",
        left: "right"
      }
    };
  },
  mixins: [responsiveMixin],
  computed: {
    ...mapGetters({
      print: "currentPrint",
      product: "currentProduct",
      finishes: "currentFinishes"
    }),
    extras() {
      return this.finishes.filter(finish => {
        if (finish.level === 9 || finish.level === 8) {
          return finish;
        }
        return false;
      });
    },
    normalFinishes() {
      return this.finishes.filter(finish => {
        if (finish.level !== 9 && finish.level !== 8) {
          return finish;
        }
        return false;
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name === "basket" ||
      (to.name === "checkout" && this.print.complete === false)
    ) {
      if (this.errors.length === 0 || this.selected.length === 0) {
        // add to state
        this.$store.dispatch("addFinishes", this.selected);
        next();
      } else {
        this.errors.forEach(side => {
          this.$toasted.error(this.$t("finishes." + side) + " " + this.$t("errors.missing_finish"));
        });
      }
    } else {
      next();
    }
  },
  components: {
    FinishPreview,
    FinishEditor
  }
};
</script>
<style lang="scss">
@import "~vars";
@import "~bulma/sass/utilities/mixins";

.finishes {
  display: flex;

  .icon svg {
    width: 2rem;
    height: 2rem;
  }

  .selection {
    width: 100%;

    @include desktop() {
      flex: 1 0 40%;
    }
  }
}

.finish-extras {
  margin-top: 4rem;
}

.finishes-sort {
  margin-top: 2rem;
}

.preview {
  margin-top: -100px;
  padding-left: 3rem;
  margin-right: -3rem;
}

.disclaimer{
  p{
    border-style: dashed;
    padding: 1.5rem;
    max-width: 40rem;
  }
}
</style>
