<template>
  <div class="field">
    <label class="label">Afleveradres</label>
    <div class="select">
      <select v-model="selectedValue" @change="change(selectedValue)">
        <option :value="false">standaard afleveradres</option>
        <option v-for="(address, key) in addresses" :value="key" :key="key">{{
          address | formatted 
        }}</option>
        <option :value="999">Anders</option>
      </select>
    </div>
    <form
      class="card-content"
      v-if="selectedValue === 999"
      @submit.prevent="send"
    >
      <template v-for="key in addressFields">
        <div class="field" :key="key">
          <label class="label">{{ $t("address." + key) }}</label>
          <div class="control">
            <input
              type="text"
              class="input"
              :class="{'is-danger': Object.keys(errors).includes(key)}"
              v-model="address[key]"
              :placeholder="key"
            />
          </div>
          <ul v-if="Object.keys(errors).includes(key)" class="help is-danger">
            <li v-for="error in errors[key]" :key="key + error">
              {{ $t(error, [$t("address." + key)])}}
            </li>
          </ul>
        </div>
      </template>
      <div class="field">
        <label class="label">{{ $t("address.country") }}</label>
        <div class="select">
          <select v-model="address.country_id">
            <option value="NL" :selected="value.country_id === 'NL'">{{
              countryList.getName("NL", $store.state.context)
            }}</option>
            <option
              v-for="(country, code) in countries"
              :key="code"
              :value="code"
              :selected="code === value.country_id"
            >{{ country }}</option
            >
          </select>
        </div>
        <ul v-if="Object.keys(errors).includes('country_id')" class="help is-danger">
          <li v-for="error in errors['country_id']" :key="'country_id' + error">
            {{ $t(error, [$t("address.country")])}}
          </li>
        </ul>
      </div>
      <div class="jc-sb">
        <button type="submit" class="button is-secondary" :disabled="loading">{{ $t('ui.choose') }}</button>
        <button
          @click.prevent="saveAddress"
          v-if="save"
          class="button bg-cyan c-white is-borderless"
          :class="{'is-loading': loading}"
          :disabled="loading"
        >
          {{ $t('ui.save_close') }}
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import countryList from "i18n-iso-countries";
import nl from "i18n-iso-countries/langs/nl.json";
import en from "i18n-iso-countries/langs/en.json";
import fr from "i18n-iso-countries/langs/fr.json";
import de from "i18n-iso-countries/langs/de.json";
countryList.registerLocale(nl);
countryList.registerLocale(en);
countryList.registerLocale(fr);
countryList.registerLocale(de);

export default {
  props: {
    value: [Object, Boolean]
  },
  data() {
    return {
      address: {
        name: this.value.hasOwnProperty("name") ? this.value.name : "",
        dept: this.value.hasOwnProperty("dept") ? this.value.dept : "",
        attn: this.value.hasOwnProperty("attn") ? this.value.attn : "",
        phone: this.value.hasOwnProperty("phone") ? this.value.phone : "",
        email: this.value.hasOwnProperty("email") ? this.value.email : "",
        street: this.value.hasOwnProperty("street") ? this.value.street : "",
        zip: this.value.hasOwnProperty("zip") ? this.value.zip : "",
        city: this.value.hasOwnProperty("city") ? this.value.city : "",
        country_id: this.value.hasOwnProperty("country_id")
          ? this.value.country_id
          : null
      },
      errors: [],
      loading: false,
      countryList,
      save: true,
      countries: {},
      selectedValue: this.value.hasOwnProperty("key") ? this.value.key : false
    };
  },
  filters: {
    formatted(address) {
      return address
        ? `${address.name} - ${address.street}, ${address.city}`
        : "Standaard Aflever adres";
    }
  },
  mounted() {
    this.countries = this.countryList.getNames(this.$store.state.context);
  },
  methods: {
    change(val) {
      if (val !== 999) {
        // Create copy and emit
        if (val === false) {
          this.$emit("input", false);
          this.$emit("close");
          return;
        }

        const address = this.addresses[val];
        address.key = val;
        this.$emit("input", address);
        this.$emit("close");
      }
    },
    async saveAddress() {
      this.errors = [];
      try {
        this.loading = true;
        await this.$http.post('/addresses', this.address);
        this.$toasted.success('Adres opgeslagen');
        this.save = false;
        this.send();
      } catch ({response}) {
        const {data, status} = response;
        if (status !== 422) {
          this.$toasted.error('Something went wrong');
        } else {
          this.errors = data;
        }
      } finally {
        this.loading = false;
      }
    },
    send() {
      const address = this.address;
      address.key = 999;
      this.$emit("input", address);
      this.$emit("close");
    }
  },
  computed: {
    addresses() {
      return this.$store.state.addresses.filter(
        address => address.type === "delivery"
      );
    },
    addressFields() {
      return Object.keys(this.address).filter(key => key !== "country_id");
    },
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
