/**
 * based on: imcvampire/vue-axios
 */

export default function plugin(Vue, axios) {
  if (plugin.installed) {
    return;
  }

  if (!axios) {
    throw new Error("You have to install axios");
  }

  Vue.http = axios;

  Object.defineProperty(Vue.prototype, "$http", {
    get() {
      return axios;
    }
  });
}
